/* eslint-disable no-magic-numbers */
import React from "react";
import Alert from "../../components/alert/Alert";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import useTogglePassword from "../../hooks/useTogglePassword";
import PasswordRequirement from "../../components/password_requirement/PasswordRequirement";
import { generateClassName } from "../../services/util";
import { validatePassword } from "../../services/validation";
import { useNavigate } from "react-router-dom";
import handleChangePassword from "./account/handleChangePassword";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import useScrollPage from "../../hooks/useScrollPage";
import useTitle from "../../hooks/useTitle";

function AccountDoc() {
    useScrollPage();
    useTitle("COhatch+ Account");

    const { userState, setUserState, setSubscription } = useUserContext();
    const navigate = useNavigate();
    const passwordRef = React.useRef(null);
    const passwordVisibilityRef = React.useRef(null);

    //Password Change;
    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [validNewPassword, setValidNewPassword] = React.useState(false);
    const [changingPassword, setChangingPassword] = React.useState(false);

    React.useEffect(() => {
        const result = validatePassword(newPassword);
        setValidNewPassword(result);
    }, [newPassword]);

    const [togglePasswordVisibility] = useTogglePassword(passwordRef, passwordVisibilityRef);

    const changePassword = async (event) => {
        event.preventDefault();
        if (!validNewPassword || !oldPassword) {
            notify("One or more fields are invalid. Check and try again.", "error");
            return;
        }

        const payload = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        try {
            setChangingPassword(true);
            const response = await handleChangePassword(payload, userState.accessToken);
            if (response.status === "success") {
                setOldPassword("");
                setNewPassword("");
                notify("Your password update was successful. You will logged out in 5 seconds to re-authenticate.", "info");
                setTimeout(() => {
                    setUserState({});
                    setSubscription({});
                    navigate("/login");
                }, 5000);
            } else if (response.status === "fail") {
                let displayMessage = "";
                if (Array.isArray(response.message)) {
                    displayMessage = response.message[0].message;
                } else {
                    displayMessage = response.message;
                    if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                        displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
                    }
                }
                notify(displayMessage, "error");
            }
        } catch (error) {
            notify("Something went wrong. Please try again later.", "error");
        } finally {
            setChangingPassword(false);
        }
    };

    return (
        <div className="max-w-7xl mt-4 mx-auto sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
                <form onSubmit={changePassword} className="w-full space-y-3 p-4">
                    <h1 className="text-lg text-success-800 font-semibold">Change Your Password</h1>
                    <Alert
                        color="yellow"
                        heading="Changing your password"
                        body={[
                            "Changing your password will require you to log out and log back in.",
                            "The new password will be active immediately after resetting your password.",
                            "Please ensure you don't have any unsaved changes before changing your password.",
                        ]}
                    />

                    <div>
                        <label htmlFor="old_password" className="block text-sm font-medium text-gray-700">
                            Old Password
                        </label>
                        <div className="mt-1">
                            <input
                                onChange={(event) => setOldPassword(event.target.value)}
                                type="password"
                                name="old_password"
                                id="old_password"
                                value={oldPassword}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                                placeholder="Old password"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
                            New Password
                        </label>
                        <div className="relative mt-1">
                            <input
                                onChange={(event) => setNewPassword(event.target.value)}
                                ref={passwordRef}
                                type="password"
                                name="new_password"
                                id="new_password"
                                value={newPassword}
                                autoComplete="off"
                                className={generateClassName(!validNewPassword && newPassword)}
                                placeholder="New password"
                            />
                            <span ref={passwordVisibilityRef} onClick={togglePasswordVisibility} className="absolute top-2 right-[2%] material-icons-outlined text-gray-400 cursor-pointer">
                                visibility
                            </span>
                            <PasswordRequirement />
                        </div>
                    </div>
                    <div className="w-full flex justify-end">
                        <div>
                            <ButtonPrimary text={changingPassword ? "Please wait..." : "Change Password"} size="md" loading={!validNewPassword || changingPassword ? true : false} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AccountDoc;
