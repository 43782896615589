/* eslint-disable */
import React, { useEffect, useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import Spinner from "../../components/spinner/Spinner";
import notify from "../../services/toast";
import DefaultState from "../../components/default_state/DefaultState";
import getUserDocuments from "./getUserDocuments";
import { MAGIC_NUMBERS } from "../../constant";
import { Link } from "react-router-dom";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import NewDashboardNavbar from "../../components/new-dashboard-navbar/NewDashboardNavbar";

function CohatchDocuments() {
  const { userState } = useUserContext();
  const [documents, setDocuments] = useState([]);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 0,
    remaining: 0,
    limit: 11,
  });

  // Mapping for document types
  const DOCUMENT_TYPES_MAP = {
    MEAgreement: "Meeting & Event Agreement",
    HyggeCoworkingAgreement: "Hygge Coworking Agreement",
    CCAuthorization: "Credit Card Authorization",
    COhatchMembershipTerms: "Terms and Conditions of COhatch Memberships"
  };

  // Get Document Type Label
  const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

  // Get Market Label
  const getMarketLabel = (marketValue) => {
    const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
    return market ? market.label : marketValue;
  };

  // Get Location Label
  const getLocationLabel = (marketValue, locationValue) => {
    // Loop through all cities in COHATCH_LOCATIONS
    for (const city of COHATCH_LOCATIONS.cities) {
        if (city[marketValue]) {  // Check if the city has this market
            const market = city[marketValue];
            const locations = market.locations || []; // Get locations for the market
            const location = locations.find(item => item.value === locationValue);

            if (location) {
                return location.label;
            } else {
                console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                return locationValue; // Fallback to the original value
            }
        }
    }
    return marketValue; // Fallback if market is not found
};


  // Get Meeting Room Label
  const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
    const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || []; // Ensure valid nesting
    const room = rooms.find(item => item.value === roomValue);
    return room ? room.label : roomValue;
  };


  const fetchDocuments = async (accessToken) => {
    try {
      setIsDocumentsLoading(true);

      const response = await getUserDocuments(accessToken);
      console.log("Response from getUserDocuments:", response);

      if (response.status === "success") {
        setDocuments(response.data);
        setPagination({
          ...pagination,
          total: response.total,
          remaining: response.remaining,
        });
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage =
              "An error has occurred. Please try again later. If this error persists, please contact support.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      console.log("Something went wrong while fetching documents", error);
    } finally {
      setIsDocumentsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments(userState.accessToken);
  }, [userState.accessToken]);

  return (
    <>

      <div className="bg-gray-50 min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-semibold text-gray-900 mb-8 text-center">
            Your Documents
          </h2>

          {isDocumentsLoading ? (
            <div className="flex justify-center items-center">
              <Spinner displayText="Loading documents..." />
            </div>
          ) : documents.length > 0 ? (
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Display Documents in Cards */}
              {documents.map((document) => (
                <div
                  key={document._id}
                  className="rounded-xl shadow-lg p-6 hover:shadow-2xl transform transition-all duration-300 hover:scale-105"
                >
                  <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-semibold text-primary-400">
                      {getDocumentTypeLabel(document.documentType)}
                    </div>
                    <div className="text-sm text-gray-600">
                      {new Date(document.createdAt).toLocaleDateString()}
                    </div>
                  </div>

                  {/* <div className="text-sm text-gray-700 mb-4">
              <strong className="text-success-800">Meeting Time:</strong> {document.meetingStart} - {document.meetingEnd}
            </div> */}

                  {/* Display Market and Location */}
                  {document.meetingLocation && (
                    <div className="text-base text-gray-600 mb-4">
                      <div>
                        <strong className="text-success-800">Market:</strong> {getMarketLabel(document.meetingLocation.market)}
                      </div>
                      <div>
                        <strong className="text-success-800">Location:</strong> {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)}
                      </div>
                      <div>
                        <strong className="text-success-800">Meeting Room:</strong> {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                      </div>
                    </div>
                  )}

                  {/* Action Button */}
                  <Link
                    to={`/${document.documentType === "MEAgreement"
                        ? `dashboard/cohatch_documents/meeting_event_agreement/${document._id}`
                        : document.documentType === "CCAuthorization"
                          ? `dashboard/cohatch_documents/credit_card_authorization/${document._id}`
                          : ""
                      }`}
                    className="inline-block mt-4 bg-success-800 text-white font-semibold px-4 py-2 rounded-lg hover:bg-success-700 focus:outline-none focus:ring-2 focus:ring-success-500"
                  >
                    {!document?.signature ? "View & Sign Document" : "View"}
                  </Link>



                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center">
              <DefaultState
                icon="file"
                heading="No Documents Found"
                description="You have no documents assigned."
              />
            </div>
          )}

          {/* Optional pagination component */}
          {pagination.total > pagination.limit && (
            <div className="mt-8 flex justify-center">
              <Link
                to="#"
                onClick={() => fetchDocuments(userState.accessToken)}
                className="text-success-600 hover:text-success-900 font-medium"
              >
                Load More
              </Link>
            </div>
          )}
        </div>
      </div>
    </>

  );
}

export default CohatchDocuments;
