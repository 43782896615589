import React from "react";
import Footer from "../../../components/footer/Footer";
import HomeNavigation from "../../../components/home_navigation/HomeNavigation";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function ElectronicCommunication() {
  useScrollPage();
  useTitle("COhatch+ Electronic Communication Policy");

  return (
    <div className="w-full flex flex-col">
      <div className="sticky top-0 w-full z-[999999]">
        <HomeNavigation />
      </div>
      <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
        <h1 className="w-full text-4xl md:text-5xl font-bebas text-success-800 font-bold">Electronic Communications Policy</h1>
      </div>


      <section>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-12">
            <div className="text-lg  mx-auto">
              <h1>
                <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
                  We are required by law to send you certain information in writing. This Electronic Communications Policy (“Policy”) describes how we will communicate that information to you.
                </span>
                <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
                  This Policy is a part of our General Terms of Use. By using any of COhatch’s services, you are agreeing to this Policy and our General Terms of Use.
                </span>

                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  1. Information We Will Send You
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                COhatch will sometimes need to send you important messages about your use of COhatch’s website, mobile apps, or other services (collectively, our “Services”). This Policy covers all of those messages, including the terms, policies and user agreements applicable to your use of the Services, billing statements, transaction information, privacy disclosures, tax statements, and other legal documents that will be provided to you electronically (collectively, the “Electronic Communications”).
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  2. Communications Will Be Sent Electronically
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Under this Policy, you give your consent for COhatch to provide you with the Electronic Communications (including all legal terms and legally-required disclosures) electronically. You also agree that your agreement with electronic terms and disclosures has the same legal effect as if you had signed an agreement on paper.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                To ensure that you receive Electronic Communications, you should make sure that the contact information in your COhatch account is accurate. COhatch is not responsible for your failure to receive Electronic Communications if you failed to update your contact information.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  3. Delivery of Information
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                COhatch may provide you with Electronic Communications by (a) emailing them to you at the email address listed in your COhatch account, (b) posting them on the COhatch website or mobile applications, or (c) making them available via a website designated in an email notice to you.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  4. Withdrawing Consent for Electronic Communications
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                You may withdraw your consent to receive Electronic Communications at any time by submitting a request to us at: support@cohatchplus.com
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Note that if you withdraw your consent to receive Electronic Communications, such withdrawal will only be effective after COhatch has had a reasonable period of time to process your withdrawal (usually 3 months). Additionally, if you withdraw your consent to receive Electronic Communications, and COhatch is required to send you paper copies of communications, COhatch may cancel your access to the Services.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                In order for COhatch to send you paper copies, you must have a current mailing address on file in your COhatch account. COhatch reserves the right to charge you reasonable fees to cover the costs of sending you paper copies of Electronic Communications.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <p className="mt-12 text-base text-gray-500 leading-8">
                Last updated: March 10, 2022
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default ElectronicCommunication;