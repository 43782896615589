import React from "react";
import Footer from "../../../components/footer/Footer";
import HomeNavigation from "../../../components/home_navigation/HomeNavigation";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function COhatchPrivacy() {
  useScrollPage();
  useTitle("COhatch Policy");
  
  return (
    <div className="w-full flex flex-col">
      <div className="sticky top-0 w-full z-[999999]">
        <HomeNavigation />
      </div>
      <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
        <h1 className="w-full text-4xl md:text-5xl font-bebas text-success-800 font-bold">COhatch Privacy Policy</h1>
      </div>


      <section>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-12">
            <div className="text-lg  mx-auto">
              <h1>
                <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
                  This COhatch Privacy Policy (this “Policy”) explains the privacy practices for COhatchplus.com (referred to as the “Site”), COhatch’s mobile applications (each, an “App” or collectively, the “Apps”), and all other services (each, a “Service” or collectively with the Site referred to as the “Services”) provided by COhatch Marketplace, LLC (referred to as “COhatch” “we”, “us”, or “our”).
                </span>
                <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
                  This Policy does not apply to the practices of third-parties, including without limitation, users of COhatch’s public Application Program Interface (“API”) who may also collect or receive data in connection with your use of the Services.
                </span>

                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  1. Our Privacy Policy
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                We process your personal information to run our business and provide our users with the Services. By using our Services, including browsing or posting, you affirm that you: (i) are of legal age to be bound by this Policy, (ii) have read and understand this Policy, and (iii) agree to be bound by this Policy. If you do not want us to collect or process your personal information in the ways described in this Policy, you should not use the Services.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                COhatch’s General Terms of Use require all account owners to be at least 18 years of age. Minors under 18 years of age are not permitted to use COhatch’s Services. You are responsible for any and all account activity conducted by a minor on your account. We do not knowingly “sell,” as that term is defined under applicable law, including the California Consumer Privacy Act, the personal information of minors.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                By using the Services, you acknowledge that COhatch will use your information in the United States, and any other country where COhatch operates. Please be aware that the privacy laws and standards in certain countries, including the rights of authorities to access your personal information, may differ from those that apply in the country in which you reside. We will transfer personal information only to those countries to which we are permitted by law to transfer personal information as more fully described in the “Transfers” Section of this Policy.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  2. Information Collected or Received
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                In the course of providing our Services, we collect or receive your personal information in a few different ways. We obtain the categories of personal information listed below from the following sources: (i) directly from you, for example, from forms you complete or during registration; (ii) indirectly from you based on your activity and interaction with our Services, or from the device or browser you use to access the Services; (iii) from our vendors and suppliers that help provide the Services you may interact with (such as, for example, for payments or customer support), and (iv) from our third-party advertising and marketing partners. Often, you choose what information to provide, but sometimes we require certain information to provide you the Services. COhatch uses the personal information it receives and collects in accordance with the purposes described in this Policy.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                A. Registration, Account Setup, Service Usage: In order to use the Services, you will need to submit a valid email address. If you register, you will need to submit a name associated with your account. You may modify that name through your account settings. You need to provide this information to enable us to provide you with the Services. Additional information, such as a business name, billing and payment information (including billing contact name, address, and credit card information), a telephone number, and/or a physical postal address, may be necessary in order for us to provide a particular Service. In order to use certain Services, you may be required to complete an application. Information that you submit through the application process will not be displayed publicly and will only be used internally by COhatch and its service providers, unless otherwise specified.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                B. Profile: You may provide your name and other personal information (such as birthday, gender, location) in connection with your account and activity. You can edit or remove this information through your account settings. The name associated with your account (which you may modify in your account settings) is publicly displayed and connected to your COhatch activity. Other members may see: (i) the date you joined; (ii) information relating to products or services you review, and related photos; (iii) your profile information; (iv) comments you post in our community spaces; and (v) information you decide to share via social networks. From time to time, we may also ask you about certain demographic information, such as your race, ethnicity, sexual orientation, or gender identity, in relation to specific marketing and advocacy projects and campaigns. Providing this demographic information is entirely voluntary and at the time we ask we will also seek your consent to use it.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                C. Automated Information: COhatch automatically receives and records information from your browser or your mobile device when you visit the Site, use the Apps, or use certain features of the Services, such as your IP address or unique device identifier, cookies, and data about which pages you visit and how you interact with those pages in order to allow us to operate and provide the Services. This information is stored in log files and is collected automatically. COhatch may also receive similar information (like, for example, IP addresses and actions taken on the device) provided by a connected device such as a voice-activated assistant or Smart TV. We may combine this information from your browser or your mobile device with other information that we or our advertising or marketing partners collect about you, including across devices. This information is used to prevent fraud and to keep the Services secure, to analyze and understand how the Services work for members and visitors, and to provide advertising, including across your devices, and a more personalized experience for members and visitors.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                D. Data from COhatch Vendors and Suppliers: We also may receive information from our vendors and suppliers about you. This information can include customer service interactions, payments information, shipping information, and information shared in COhatch forums.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                E. Location Information: We may collect information about your use of the Services for advertising, for analytics, to serve content, and to protect the Services. This can include your IP address, browser information (including referrers), device information (such as iOS IDFA, IDFV for limited non-advertising purposes, Android AAID, and, when enabled by you, location information provided by your device). When you use the Apps, you can choose to share your geolocation details with COhatch in order to use certain functions (although you don't need to share these details).
                <p className="text-base text-gray-500 leading-8">
                  We obtain location information you provide in your profile or your IP address. With your consent, we may also determine location by using other information from your device, such as precise location information from GPS or information about wireless networks or cell towers near your mobile device. We use and store information about your location to provide features and to improve and customize the Services, for example, for COhatch internal analytics and performance monitoring; localization, regional requirements, and policies for the Services; for local content, search results, and recommendations; for mapping services; and (using non-precise location information) marketing. If you have consented to share your precise device location details but would no longer like to continue sharing that information with us, you may revoke your consent to the sharing of that information through the settings on the Apps or on your mobile device. Certain non-precise location services, such as for security and localized policies based on your IP address or submitted address, are critical for the Site to function. We will only share your geolocation details with third-parties (like our mapping, payments, or, to the extent applicable, advertising providers) in order to provide you with the Services. You may also choose to enable the Apps to access your mobile device’s camera to upload photographs to COhatch.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                F. Analytics Information: We use data analytics software to ensure Site and App functionality and to improve the Services. This software records information such as how often you use the Apps, what happens within the Apps, aggregated usage, performance data, app errors and debugging information, and where the Apps were downloaded from. We do not link the information we store within the analytics software to any personally identifiable information that you submit within the mobile application.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                G. Information from Third Parties: Some users visitors may choose to connect to COhatch or register a COhatch account using an external third-party application, such as Facebook, or an app developed using COhatch’s API. Connecting your COhatch account to third-party applications or services is optional. If you choose to connect your account to a third-party application, it is subject to your agreements with that third party (like their Terms of Use and privacy notices), and COhatch can receive information from that application. We may also collect public information in order to connect with you, such as when we communicate with you over social media. We may use that information as part of providing the Services to you. You can also choose to share some of your activity on COhatch on certain social media networks which are connected to your COhatch account, and you can revoke your permission anytime in your account settings. Certain third-party applications that you use to create or sign in to your account may provide you with the option to discontinue receiving messages from us or allows you to forward those messages to another email address. If we are unable to send you critical messages about your account, we may suspend or terminate your account.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                H. Non-Member Information: COhatch receives or obtains information (for example, an email address or IP address) about a person who is not yet a registered COhatch member (a “Non-member”) in connection with certain COhatch features, such as when a Non-member chooses to subscribe to a COhatch newsletter, a member invites a Non-member to visit the Site, a member uploads Non-member information using the contact uploader feature or a Non-member engages with a posting. Non-member information is used only for the purposes disclosed when it was submitted to COhatch, for purposes necessary to the functioning of COhatch’s Services or where COhatch has a legitimate interest, as disclosed in the “Information Uses, Sharing, & Disclosure” Section below, or to facilitate action authorized by a member or the non-member.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  3. Messages from COhatch
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                On occasion, COhatch will need to contact you. Primarily, these messages are delivered by email, or by push notifications for a variety of reasons, including marketing, transactions, advocacy, and Service update purposes. If you no longer wish to receive push notifications, you can disable them at device level. You can opt out of receiving marketing communications via email or messages in your account settings or by following the unsubscribe link in any marketing email you receive. To ensure you properly receive notifications, we will need to collect certain information about your device, such as operating system and user identification information. Every account is required to keep a valid email address on file to receive messages. COhatch may also contact you by telephone to provide member support or for transaction-related purposes if you request that we call you. Additionally, and with your consent where required by applicable law, COhatch may send you an SMS (or similar) message, or reach out to you by telephone, in order to provide you with customer support, for research or feedback, or to provide you with information about products and features that you may find of interest. You can update your contact preferences in your account settings.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Some messages from COhatch are Service-related and necessary for members and Guest Checkout users. You understand and agree that COhatch can send you non-marketing emails, or messages, such as those related to your posts, your account, or security. Examples of Service-related messages include an email address confirmation/welcome email when you register your account, notification of an order, Service availability, modification of key features or functions, relaying messages with buyers, and correspondence with COhatch Support team (including by chat interface or the other methods of communication as described above).
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                When you register for an account, subscribe to a newsletter, or provide us with your email address or phone number such as for a Guest Checkout purchase, you receive notice of and agree (in some jurisdictions and situations, by an additional unambiguous consent) to receive marketing emails and messages from us. You can unsubscribe at any time from marketing emails or messages through the opt-out link included in marketing emails or messages. Account holders can also control which marketing emails or messages they receive from COhatch through their account settings. Please note that some changes to your account settings may take a few days to take effect.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  4. Community
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                COhatch is a marketplace and a community. We offer several features that allow members to connect and communicate in public or semi-public spaces. You do not have to use these features, but if you do, please use common sense and good judgment when posting in these community spaces or sharing your personal information with others through the Services. Be aware that any personal information that you choose to submit there can be read, collected, or used by others, or could be used to send unsolicited messages to you. We engage certain third-parties to assist in providing community services to you and, in the context of that relationship, we need to share some of your information with such third-parties in order to provide that Service. This may include, for example, information from your COhatch account that is publicly available, your account’s status, your posts, your COhatch username and/or business name, the display name you’ve optionally chosen to share publicly in your COhatch account, the date that you registered on COhatch, as well as your language and regional settings on the Site. However, your posts may remain public after your account is closed, although your name will no longer be displayed alongside the post. You are responsible for the personal information that you choose to post in community spaces through the Services.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Another member may follow your public activity on the Site to receive updates. You may choose to make certain activities private through your account settings.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  5. Information Uses, Sharing, & Disclosure
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                We respect your privacy. COhatch will not disclose your name, email address, or other personal information to third-parties without your consent, except as specified in this Policy.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                A. Legal Bases. When you access or use the Services, we collect, use, share, and otherwise process your personal information for the purposes described in this Policy. We rely on a number of legal bases to use your information in these ways. These legal bases include where:
                <ul className="list-disc pl-10">
                  <li>Necessary to perform the contractual obligations in our Terms of Use and in order to provide the Services to you; You have consented to the processing, which you can revoke at any time;</li>
                  <li>Necessary to comply with a legal or regulatory obligation, a court order, or to exercise or defend any impending or asserted legal claims;</li>
                  <li>Necessary for the purposes of our or a third party’s legitimate interests, such as those of visitors, members, or partners (described in more detail below);</li>
                  <li>You have expressly made the information public;</li>
                  <li>Necessary in the public interest, such as to prevent crime;</li>
                  <li>Occasionally necessary to protect your vital interests or those of others (in rare cases where we may need to share information to prevent loss of life or personal injury); and</li>
                  <li>We principally rely on consent (which can be withdrawn at anytime) (i) to send marketing messages, (ii) for third-party data sharing related to advertising, and, to the extent applicable, or (iii) for the use of location data for advertising purposes.</li>
                </ul>
                <p className="text-base text-gray-500 leading-8">
                  We may advertise our Services or local businesses through a variety of different mediums and rely on your consent to do so off-site. These partners have their own privacy policies and consent mechanisms for their customers, in addition to COhatch’s controls.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  COhatch may also use first party data (e.g. your browsing patterns on our Site), and may combine it with other data collected from you, such as your location, or publicly available information, to permit us to create targeted audiences based on your known or inferred demographic and/or interests to provide you with more relevant and useful advertising on our site, in our direct marketing channels (like email), or with our off site marketing partners, like Google and Facebook, subject to their terms and privacy notice you agreed to with such partners.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                B. Our Legitimate Interests. Where we process your information on the basis of legitimate interests, we do so as follows:
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Providing and Improving our Services:</p> We use your information to improve and customize our Services, including sharing your information for such purposes, and we do so as it is necessary to pursue our legitimate interests of improving our Services for our users. This is also necessary to enable us to pursue our legitimate interests in understanding how our Services are being used, and to explore and unlock ways to develop and grow our business. It is also necessary to allow us to pursue our legitimate interests in improving our Services, efficiency, interest in Services for users, and obtaining insights into usage patterns of our Services. As a core part of our Services, we have a legitimate interest in customizing your on-site experience to help you search for and discover relevant items and recommended purchases, including using this information to help local businesses find the best ways to market their products and services on COhatch.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">We rely on our legitimate interest to send you marketing messages (where permitted independent of consent) and for COhatch’s advertising programs (including COhatch’s on-site advertising and marketing).
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Keeping our Services Safe and Secure:</p>  We use your information for safety and security purposes, which can include sharing your information for such purposes, and we do so because it is necessary to pursue our legitimate interests or those of a third party in ensuring the security of our Services, preventing harm to individuals or property, or crime, enforcing or defending legal rights, or preventing damage to COhatch’s systems, or those of our users or our partners. This includes enhancing protection of our community against spam, harassment, intellectual property infringement, crime, and security risks of all kinds. We use your information to provide and improve the Services, for billing and payments, for identification and authentication, and for general research and aggregate reporting. For example, in accordance with our existing case resolution process, we may use information you provide to resolve disputes with COhatch or other members.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Site Customization:</p>We may learn about the types of products or services that you’re interested in from your browsing and posting behavior on (and off) the Site or Apps and use such information to suggest potential purchases as a result. As a core part of our Services, we have a legitimate interest in customizing your on-site experience to help you search for and discover relevant products or services and recommended purchases, including using this information to help local businesses find the best ways to market and sell their products and services on COhatch
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Legal and Safety:</p>COhatch may also retain, preserve, or release your personal information to a third party in the following limited circumstances: in response to lawful requests by public authorities, including to meet legitimate national security or law enforcement requirements; to protect, establish, or exercise our legal rights or defend against impending or asserted legal claims, including to collect a debt, or a material violation of our policies (including our Intellectual Property Policy); to comply with a subpoena, court order, legal process, regulation, or other legal requirement; or when we believe in good faith that such disclosure is reasonably necessary to comply with the law, prevent imminent physical harm or financial loss, or investigate, prevent, or take action regarding illegal activities, suspected fraud, threats to our rights or property, or violations of COhatch’s General Terms of Use. For example, if permitted due to the forgoing circumstances, your information may be shared with tax authorities or other governmental agencies. In the cases set out above, our use of your information may be necessary for the purposes of our or a third party’s legitimate interest in keeping our Services secure, preventing harm or crime, enforcing or defending legal rights, or preventing damage. Such use may also be necessary to comply with a legal obligation, a court order, or to exercise or defend legal claims or to facilitate the collection of taxes and prevention of tax fraud. It may also be necessary in the public interest (such as to prevent crime) or to protect vital interests (in rare cases where we may need to share information to prevent loss of life or personal injury).
                <p className="text-base text-gray-500 leading-8">
                  If COhatch receives a lawful, verified request for a member’s records or information in one of the limited circumstances described in the previous paragraph, COhatch may disclose personal information, which may include, but may not be limited to, a member’s name, address, phone number, and email address, and company/trading name.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Affiliated Businesses:</p>COhatch is affiliated with a variety of businesses and works closely with them for a variety of purposes, including assisting us to perform and improve the Services. These businesses may market items or services to you through the Services or, with your consent, offer promotions (including email promotions) to you. We rely on consent (which can be withdrawn at any time) to send marketing messages and for third-party sharing relating to advertising. As part of this, we may work with advertising partners such as Facebook or Google, and we may use analytics aggregated from usage information including, for example, search keywords, favorites, browsing history, and purchase history
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Aggregated Information:</p>COhatch may share demographic information with business partners, but it will be aggregated and de-personalized so that personal information is not revealed.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Service Providers:</p>Service Providers COhatch also needs to engage third-party companies and individuals (such as research companies, and analytics and security providers) to help us operate, provide, and market the Services. These third-parties have only limited access to your information, may use your information only to perform these tasks on our behalf, and are obligated to COhatch not to disclose or use your information for other purposes. Our engagement of service providers is often necessary for us to provide the Services to you, particularly where such companies play important roles like helping us keep our Services operating and secure. In some other cases, these service providers are not strictly necessary for us to provide the Services, but help us make it better, like by helping us conduct research into how we could better serve our users. In these latter cases, we have a legitimate interest in working with service providers to make our Services better.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Business Reorganization:</p>In some cases, COhatch may choose to buy or sell assets. Such transactions may be necessary and in our legitimate interests, particularly our interest in making decisions that enable our business to develop over the long term. In these types of transactions (such as a sale, merger, liquidation, receivership, or transfer of all or substantially all of COhatch’s assets), member information is typically one of the business assets that is transferred. If COhatch intends to transfer information about you, COhatch will notify you by email or by putting a prominent notice on the Site and the App, and you will be afforded an opportunity to opt out before information about you becomes subject to a different privacy policy.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Third Parties:</p>Third-party plug-ins also collect information about your use of the Site. For example, when you load a page on COhatch that has a social plug-in from a third-party site or service, such as a “Like” or “Send” button, you are also loading content from that third-party site. That site may request cookies directly from your browser. These interactions are subject to the privacy policy of the third-party site. In addition, certain cookies and other similar technologies on the Site are used by third-parties for targeted online marketing and other purposes. These technologies allow a partner to recognize your computer or mobile device each time you use the Services. Please be aware that when you use third-party sites or services, their own terms and privacy policies will govern your use of those sites or services. COhatch chooses and manages these third-party technologies placed on its Sites and Apps. However, these are third-party technologies, and they are subject to that third party's privacy policy. We rely on your consent to drop and read these cookies when not technically necessary or when not required based on another purpose such as legitimate interest.

                <p className="text-base text-gray-500 leading-8">
                  This Policy does not apply to the practices of third-parties (such as other members who sell using the Services, certain third-party providers on whom we rely to provide certain Services, or API users) that COhatch does not own or control or individuals that COhatch does not employ or manage, except as otherwise provided for in the Terms or as required by law. If you provide your information to such third-parties in connection with your use of the Services, different practices may apply to the use or disclosure of the information that you provide to them. While COhatch requires these third-parties to follow COhatch’s privacy and security requirements, COhatch does not control the privacy or security policies of such third-parties. To the full extent applicable in your jurisdiction, COhatch is not responsible for the privacy or security practices of these sellers, API users, or other websites on the internet, even those linked to or from the Services. We encourage you to read the privacy policies and ask questions of third-parties before you disclose your personal information to them.
                </p>
              </p>

            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  6. Security
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                The security of your personal information is important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and after it is received, for example encryption is used for certain information (such as credit card numbers) using TLS (transport layer security). Unfortunately, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Your account information is protected by a password. It is important that you protect against unauthorized access to your account and information by choosing your password carefully and by keeping your password and computer secure, such as by signing out after using the Services.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                If you have any questions about the security of your personal information, you can contact us at support@cohatchplus.com.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  7. Retention
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                COhatch will retain your information only for as long as is necessary for the purposes set out in this Policy, for as long as your account is active (i.e., for the lifetime of your COhatch member account), or as needed to provide the Services to you. If you no longer want COhatch to use your information to provide the Services to you, you may close your account. COhatch will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and as otherwise described in this Policy. In addition, COhatch sellers may also be required to retain and use your information in order to comply with their own legal obligations. Please note that closing your account may not free up your email address, username, or business name (if any) for reuse on a new account. We also retain log files for internal analysis purposes. These log files are generally retained for a brief period of time, except in cases where they are used for site safety and security, to improve site functionality, or we are legally obligated to retain them for longer time periods.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  8. Your Rights & Choices
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Certain privacy laws provide users with rights related to their personal information. Consistent with those laws, COhatch gives you the choice of accessing, editing, or removing certain information, as well as choices about how we contact you. You may change or correct your COhatch account information through your account settings. You may also remove certain optional information that you no longer wish to be publicly visible through the Services, such as your name. You can also request to permanently close your account and delete your personal information. Depending on your location, you may also benefit from a number of rights with respect to your information. While some of these rights apply generally, certain rights apply in limited cases.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                If you would like to manage, change, limit, or delete your personal information, you can do so via your COhatch account settings. Alternatively, you can exercise any of the rights below by contacting us at support@cohatchplus.com. Once you contact us to exercise any of your rights, we will confirm receipt of your request.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Limiting use of, or deleting, your personal information may impact features and uses that rely on that information. However, we will not discriminate against you for exercising any of your rights, including otherwise denying you goods or services, providing you with a different level or quality of Services, or charging you different prices or rates for Services. If you need further assistance, you can contact COhatch through one of the channels listed below under “Contact”. We will respond to your request within a reasonable timeframe.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Please note that we may verify your identity before we are able to process any of the requests described in this Section, and in our discretion, deny your request if we are unable to verify your identity. As a part of this process, government or other identification may be required. You may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid government issued identification, and the authorized agent’s valid government issued identification, and we may verify the authenticity of the request directly with you.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                A. Right to Access & Portability: You can access certain personal information associated with your account by visiting your account privacy settings. You can request a copy of your personal information in an easily accessible format and information explaining how that information is used.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                B. Right to Correction: You have the right to request that we rectify inaccurate information about you. By visiting your account settings, you can correct and change certain personal information associated with your account.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                C. Right to Restrict Processing: In certain cases where we process your information, you may also have the right to restrict or limit the ways in which we use your personal information.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                D. Right to Deletion: In certain circumstances, you have the right to request the deletion of your personal information, except information we are required to retain by law, regulation, or to protect the safety, security, and integrity of COhatch.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                E. Right to Object: If we process your information based on our legitimate interests as explained above, or in the public interest, you can object to this processing in certain circumstances. In such cases, we will cease processing your information unless we have compelling legitimate grounds to continue processing or where it is needed for legal reasons. Where we use your personal data for direct marketing purposes, you can object using the unsubscribe link in such communications or changing your account email settings.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                F. Right to Withdraw Consent: Where we rely on consent, you can choose to withdraw your consent to our processing of your information using specific features provided to enable you to withdraw consent, like an email unsubscribe link or your account privacy preferences. If you have consented to share your precise device location details but would no longer like to continue sharing that information with us, you can revoke your consent to the sharing of that information through the settings on your mobile device. This is without prejudice to your right to generally permanently close your account and delete your personal information.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                G. CCPA The CCPA provides California residents with the following additional rights:
                <p className="mt-4 text-base text-gray-500 leading-8">
                  <p className="italic">Right to Know: </p>California residents may request disclosure of the specific pieces and/or categories of personal information that the business has collected about them, the categories of sources for that personal information, the business or commercial purposes for collecting the information, the categories of personal information that we have disclosed, and the categories of third-parties with which the information was shared.
                </p>
                <p className="mt-4 text-base text-gray-500 leading-8">
                  <p className="italic">TRight to Opt-Out:</p>To the extent that COhatch “sells” personal information (as that term is defined under the CCPA), California residents are entitled to opt-out of the “sale” of data at any time (see below for more information).
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                H. Email & Messages. You may also control the receipt of certain types of communications from COhatch in your account settings. COhatch may send you messages about the Services or your activity. Some of these messages are required, Service-related messages for members (such as transactional messages or legal notices). Other messages are not required, such as newsletters. You can control which optional messages you choose to receive by changing your account settings, and you can learn more in the “Messages from COhatch” Section of this Policy. If you no longer wish to use the Services or receive Service-related messages (except for legally required notices), then you may close your account. Click here to learn how to close your account.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                I. Cookies. We use both technically necessary (for the functioning and security of the Services) and non-technically necessary cookies and similar technologies.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                J. Additional Disclosures for California Residents. Notice of Collection. In addition to the Rights & Choices described above, the CCPA requires disclosure of the categories of personal information collected over the past 12 months. While this information is provided in greater detail in the “Information Collected or Received Section above, the categories of personal information that we have collected – as described by the CCPA – are:
                <ul className="list-disc pl-10">
                  <li>Identifiers, including name, email address, business name, IP address, and an ID or number assigned to your account.</li>
                  <li>Other individual records such as phone number, billing address, or credit or debit card information. This category includes personal information protected under pre-existing California law (Cal. Civ. Code 1798.80(e)), and overlaps with other categories listed here.</li>
                  <li>Demographics, such as your age or gender, or, where you have provided such information to COhatch voluntarily, demographic information about your race, ethnicity, sexual orientation, or gender identity, provided in relation to specific marketing and advocacy projects and campaigns.This category includes data that may qualify as protected classifications under other California or federal laws.</li>
                  <li>Commercial information, including purchases and engagement with the Services.</li>
                  <li>Internet activity, including your interactions with our Services and what led you to our Services.</li>
                  <li>Sensory visual data, such as pictures posted on our Service.</li>
                  <li>Geolocation data provided through location enabled Services such as WiFi and GPS.</li>
                  <li>Inferences, including information about your interests, preferences and favorites.</li>
                </ul>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">The Purposes for Our Collection.</p>We collect and use these categories of personal information for our business and commercial purposes described in the “Information Uses, Sharing and Disclosure” Section above, including providing and improving the Services, maintaining the safety and security of the Services, processing purchase and sale transactions, and for advertising and marketing services.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Third Party Marketing and Advertising and Your Rights (Opt-Out of “Sale”).</p>COhatch does not sell personal information to third-parties for monetary value. However, the term “sale” is defined broadly under the California Consumer Privacy Act. To the extent that “sale” under the CCPA is interpreted to include interest based advertising or other data uses described in the “Information Uses, Sharing and Disclosure” Section above, we will comply with applicable law as to those activities.
                <p className="text-base text-gray-500 leading-8">
                  To opt-out of receiving interest-based advertising, you can exercise your choice by using your account privacy settings, or the general privacy settings link located at the footer of our homepage and most pages of the Site. The right to opt-out of interest-based advertising is available to all COhatch users.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Do Not Track Signals.</p>Your browser settings may allow you to automatically transmit a “Do Not Track” signal to online services you visit. Note, however, there is no industry consensus as to what site and app operators should do with regard to these signals. Accordingly, unless and until the law is interpreted to require us to do so, we do not monitor or take action with respect to “Do Not Track” signals. For more information on “Do Not Track,” visit http://www.allaboutdnt.com.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Shine the Light.</p>California law entitles residents to ask for a notice describing what categories of personal information we share with third-parties for their own direct marketing purposes. Other than to Sellers on COhatch (see “Buying and Selling” under the “Information Uses, Sharing and Disclosure” Section above), who must obtain your separate consent for their own marketing off of COhatch, or unless you request us to or consent to it, COhatch does not share any personal information to third-parties for their own direct marketing purposes. If you have questions about these practices, you can contact us through one of the channels listed in the “Contact” Section below.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                <p className="italic">Accessibility</p>If you have a disability and would like to access this Policy in an alternative format, please contact us using one of the channels listed in the “Contact” section below.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  9. Privacy Policy Changes
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                We may amend or update this Policy from time to time. If we believe that the changes are material, we’ll let you know by doing one (or more) of the following: (i) posting the changes on or through the Services, (ii) sending you an email or message about the changes, or (iii) posting an update in the version notes on the Apps’ platform. We encourage you to check back regularly and review any updates.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  10. Contact
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                If you have any questions:
                <ul className="list-disc pl-10">
                  <li>Contact COhatch’s Support team via support@cohatchplus.com</li>
                  <li>Write to us at the following address: COhatch Worthington, COhatch+ 659 High St. Worthington, OH 43065</li>
                </ul>
              </p>
            </div>


            <div className="text-lg  mx-auto">
              <p className="mt-12 text-base text-gray-500 leading-8">
                Last updated: March 10, 2022
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default COhatchPrivacy;