/*eslint-disable*/
import React, { useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import useUserContext from "../../hooks/useUserContext";
import getAppById from "./getAppById";
import { format } from "date-fns";
import { useParams } from "react-router-dom";


function ApplicationFile() {

  const [loader, setLoader] = useState(false);
  const {
    userState: { accessToken },
  } = useUserContext();
  const params = useParams();
  const [application, setApplication] = React.useState("");
  const downloadPDF = () => {
    const capture = document.querySelector(".full-application");
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new JsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`${application.orgName}-Application.pdf`);
    });
  };

  function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  const createdAt = application?.createdAt ? application.createdAt : null;
  var submissionDate = new Date(createdAt);
  var newDate = addYears(submissionDate, 1);
  const fetchApplication = async () => {
    try {
      const response = await getAppById(accessToken, params.applicationId);
      console.log(response);
      if (response.status === "success") {

        const { data } = response;

        setApplication(data);
      } else {

      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchApplication();

  }, []);


  return (

    <div className="m-5 ">

      <div className="mt-6 border-t border-gray-100">

        {application.type === "give" && (
          <div className="full-application">
            <h3 className="m-3 text-base font-semibold leading-7 text-gray-900">Applicant Information</h3>
            <dl className="divide-y divide-gray-100">
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  First Name & Last Name
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.firstName} {application.lastName}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Application Date
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {format(new Date(createdAt), "MMMM do, yyyy")}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Reapplication Date
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {format(new Date(new Date(createdAt).setFullYear(new Date(createdAt).getFullYear() + 1)), "MMMM do, yyyy")}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Phone Number
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.phoneNumber}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email address
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.email}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Organization Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgName}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Organization Address
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.businessAddress?.address ? application.businessAddress.address : application.businessAddress}

                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  COhatch City & Location
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.market}, {application.coLocation}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Application Type & Cause
                </dt>
                <dd className="mt-1 capitalize text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.type} Scholarship |  {application.causes?.map((cause, index) => {
                    return <p key={index}>{cause}</p>;
                  })}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Description of Your Organization and Mission:
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgMission}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  How does your organization impact the local community?(Ex. event, providing necessary services, etc.):
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgImpact}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  How are you planning to use our location?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.locationUsage}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  What are your organization's top three challenges or barriers?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.threeChallenges}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  How is your organization funded?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.funding}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Does your organization have any annual events? If so, please tell us about your events.
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.annualEvents}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Does your organization use volunteers? If so, tell us how?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.volunteers}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  # of Team Members Applying for Scholarship
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgAmount}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Business Video Link
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.businessVideo}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Are you sponsored by an employee? If so, who?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgSponsored}
                </dd>
              </div>
            </dl>
          </div>
        )}


        {application.type === "boost" && (
          <div className="full-application">
            <h3 className="m-3 text-base font-semibold leading-7 text-gray-900">Applicant Information</h3>
            <dl className="divide-y divide-gray-100">
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  First Name and Last Name
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.firstName} {application.lastName}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Application Date
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {format(new Date(createdAt), "MMMM do, yyyy")}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Reapplication Date
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {format(new Date(new Date(createdAt).setFullYear(new Date(createdAt).getFullYear() + 1)), "MMMM do, yyyy")}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Phone Number
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.phoneNumber}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email address
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.email}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Application Type & Category
                </dt>
                <dd className="mt-1 capitalize text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.type} Scholarship | {application.category}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Organization Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgName}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Organization Address
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.businessAddress?.address ? application.businessAddress.address : application.businessAddress}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  COhatch City & Location
                </dt>
                <dd className="capitalize mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.market} , {application.coLocation}
                </dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Date Founded
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.dateFounded}
                </dd>
              </div>
              {application.orgMission ? (
                <>
                  <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Description of Your Organization and Mission?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.orgMission}
                    </dd>
                  </div>
                </>
              ) :
                <>
                  <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Tell Us About Your Business Plan?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.businessPlan}
                    </dd>
                  </div>
                  <div className="bg-white  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      When Do You Project Your Breakeven Point?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.breakevenPoint}
                    </dd>
                  </div>
                  <div className="bg-gray-50  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      How Many Employees Do You Have? How Many Do You Plan To Hire In The Next 12/24/36 Months?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.planHire}
                    </dd>
                  </div>
                  <div className="bg-white  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      How Are You Planning To Use Our Space?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.locationUsage}
                    </dd>
                  </div>
                  <div className="bg-gray-50  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      What Are Your Organization's Top Three Challenges?
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.threeChallenges}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      # of Team Members Applying for Scholarship
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {application.orgAmount}
                    </dd>
                  </div>
                </>}
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Are you sponsored by an employee? If so, who?
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.orgSponsored}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Business Video Link
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {application.businessVideo}
                </dd>
              </div>

            </dl>
          </div>
        )}
        <dl className="divide-y divide-gray-100">
          <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Attachments
            </dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        {application.orgName}_application.pdf
                      </span>
                      <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                    </div>
                  </div>

                  <div className="float-right">
                    <div className="">
                      <button className="w-full bg-success-800 text-white text-center rounded py-2 shadow-sm px-2.5 py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition ease-linear duration-500"
                        onClick={downloadPDF}
                        disabled={!(loader === false)}>
                        {loader ? (<span>Downloading</span>) : (<span>Download</span>)}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>


      </div>
    </div>


  );
}

export default ApplicationFile;
