/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import { useParams } from "react-router-dom";
import getDocDataById from "./getDocDataById";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import { jsPDF } from "jspdf";
import config from "../../config";
import updateDocument from "./updateDocument";
import SignatureCanvas from "react-signature-canvas";

function CCAuthorizationDoc() {
    const {
        userState: { accessToken },
    } = useUserContext();
    const params = useParams();
    const [document, setDocument] = React.useState("");
    const [paymentType, setPaymentType] = useState("creditCard"); // Default to "creditCard"
    const [creditCard, setCreditCard] = useState({
        cardholderName: '',
        cardNumber: '',
        expirationDate: '',
        cvv: '',
        zipCode: ''
    });
    const [bankInfo, setBankInfo] = useState({
        bankName: "",
        routingNumber: "",
        accountNumber: "",
        accountType: "",
        accountOwner: "",
    });
    const [isDocumentSubmitted, setIsDocumentSubmitted] = useState(false);
    const [signature, setSignature] = useState(null); // Stores base64 image
    const [savedSignature, setSavedSignature] = useState(null); // Stores preview URL
    const signaturePadRef = useRef(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Reset credit card fields if switching to ACH
        if (paymentType === 'ach') {
            setCreditCard({
                cardholderName: '',
                cardNumber: '',
                expirationDate: '',
                cvv: '',
                zipCode: ''
            });
        }
        // Reset bank info fields if switching to Credit Card
        if (paymentType === 'creditCard') {
            setBankInfo({
                bankName: '',
                routingNumber: '',
                accountNumber: '',
                accountType: '',
                accountOwner: ''
            });
        }
    }, [paymentType]);

    const validateField = (name, value) => {
        let errorMsg = "";

        switch (name) {
            case "cardholderName":
                if (!/^[A-Za-z\s]+$/.test(value.trim())) {
                    errorMsg = "Cardholder name should contain only letters and spaces.";
                }
                break;
            case "cardNumber":
                if (!/^\d{16}$/.test(value)) {
                    errorMsg = "Card number must be exactly 16 digits.";
                }
                break;
            case "expirationDate":
                if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
                    errorMsg = "Expiration date must be in MM/YY format.";
                }
                break;
            case "cvv":
                if (!/^\d{3}$/.test(value)) {
                    errorMsg = "CVV must be exactly 3 digits.";
                }
                break;
            case "zipCode":
                if (!/^\d{5}$/.test(value)) {
                    errorMsg = "ZIP code must be exactly 5 digits.";
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMsg }));
    };


    const DOCUMENT_TYPES_MAP = {
        MEAgreement: "Meeting & Event Agreement",
        HyggeCoworkingAgreement: "Hygge Coworking Agreement",
        CCAuthorization: "Credit Card Authorization",
        COhatchMembershipTerms: "Terms and Conditions of COhatch Memberships"
    };

    // Helper function to get the display label
    const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

    const getMarketLabel = (marketValue) => {
        const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
        return market ? market.label : marketValue;
    };

    const getLocationLabel = (marketValue, locationValue) => {
        // Loop through all cities in COHATCH_LOCATIONS
        for (const city of COHATCH_LOCATIONS.cities) {
            if (city[marketValue]) {  // Check if the city has this market
                const market = city[marketValue];
                const locations = market.locations || []; // Get locations for the market
                const location = locations.find(item => item.value === locationValue);

                if (location) {
                    return location.label;
                } else {
                    console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                    return locationValue; // Fallback to the original value
                }
            }
        }
        return marketValue; // Fallback if market is not found
    };


    const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
        const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || [];
        const room = rooms.find(item => item.value === roomValue);
        return room ? room.label : roomValue;
    };

    const fetchDocument = async () => {
        try {
            const response = await getDocDataById(accessToken, params.documentId);
            if (response.status === "success") {
                const { data } = response;
                setDocument(data);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchDocument();
    }, []);

    // Helper function to format time to AM/PM
    const formatTime = (timeString) => {
        const timeArray = timeString.split(":");
        let hours = parseInt(timeArray[0]);
        const minutes = timeArray[1];
        const period = hours >= 12 ? "PM" : "AM";

        // Convert 24-hour time to 12-hour format
        hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

        // Return formatted time
        return `${hours}:${minutes} ${period}`;
    };


    const termsContent = [
        {
            header: `PAYMENT + CANCELLATION POLICY`,
            content: [
                `Payment is due upon booking and can be made by card or ACH. Credit card charges will be subject to a 3% service fee.
                    Reservations are refundable/changeable up to two weeks prior to the meeting. If changes need to be made within 2 weeks of
                    your meeting, the invoice becomes non-refundable. A full credit will be applied to a future booking.`,
            ]
        },
        {
            header: "",
            content: [
                `COhatch is a weapons free environment. No person, member, guest, or employee is permitted to possess or carry a firearm in
                    facilities operated or managed by COhatch, unless otherwise approved by COhatch's third party safety team (Safe Passage
                    Consulting). Subsequently, open carry of any weapons are otherwise prohibited unless they are uniformed on-duty law
                    enforcement.`,
            ]
        },

    ];

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setSignature(null);
        setSavedSignature(null);
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            alert("Please provide a signature before saving.");
            return;
        }

        const sigData = signaturePadRef.current.toDataURL("image/png");
        setSignature(sigData);
        setSavedSignature(sigData); // Display preview before submission
    };

    const uploadSignatureToDigitalOcean = async (base64Signature) => {
        try {
            // Convert Base64 to Blob
            const blob = await fetch(base64Signature)
                .then(res => res.blob())
                .catch(err => {
                    return null;
                });

            if (!blob) {
                return null;
            }

            // Create a FormData object
            const formData = new FormData();
            formData.append("width", "400"); // Required width
            formData.append("height", "150"); // Required height
            formData.append("folder", "COhatch-Documents/CCAuthorization/Signatures"); // Folder path
            formData.append("image", blob, "signature.png"); // "image" instead of "file"

            // Send the request to the correct API endpoint
            const response = await fetch(`${config.API_BASE_ENDPOINT}/uploads/images`, {
                method: "POST",
                body: formData,
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (!response.ok) {
                const responseText = await response.text();
                return null;
            }

            // Get the uploaded image URL from the response
            const data = await response.json();
            return data?.data?.url || data?.data?.small?.url;
        } catch (error) {
            return null;
        }
    };

    const generateAndSavePDF = async () => {
        const doc = new jsPDF();
        doc.text("Agreement Form", 20, 20);

        doc.text(`Meeting Location: ${getMarketLabel(document.meetingLocation.market)} - ${getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} - ${getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location)}`, 20, 30);
        doc.text(`Meeting Date: ${new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}`, 20, 40);
        doc.text(`Meeting Start Time: ${document.meetingStart}`, 20, 50);
        doc.text(`Meeting End Time: ${document.meetingEnd}`, 20, 60);
        doc.text(`Rate (per Hour): $${document.rate}`, 20, 70);

        let yPosition = 80;
        termsContent.forEach((term) => {
            doc.setFontSize(14);
            doc.text(term.header, 20, yPosition);
            yPosition += 10;

            term.content.forEach((item) => {
                if (Array.isArray(item)) {
                    item.forEach((point) => {
                        doc.setFontSize(12);
                        doc.text(`- ${point}`, 20, yPosition);
                        yPosition += 6;
                    });
                } else {
                    doc.setFontSize(12);
                    doc.text(item, 20, yPosition);
                    yPosition += 6;
                }
            });
            yPosition += 10;
        });

        // Instead of just generating the PDF, we will now upload it to the server and return the URL.
        const formData = new FormData();
        formData.append("folder", "COhatch-Documents/CCAuthorization/PDFs");
        formData.append("file", doc.output('blob'), "form.pdf");

        const response = await fetch(`${config.API_BASE_ENDPOINT}/uploads/files`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!response.ok) throw new Error("Failed to upload PDF.");

        const data = await response.json();
        return data?.data?.url || data?.data?.small?.url;
    };

    const handleSubmit = async () => {
        // Check if all required fields are filled
        const { cardholderName, cardNumber, expirationDate, cvv, zipCode } = creditCard;
        const { bankName, routingNumber, accountNumber, accountType, accountOwner } = bankInfo;

        // Check if either credit card or bank account is filled out
        const isCreditCardFilled =
            cardholderName && cardNumber && expirationDate && cvv && zipCode;
        const isBankInfoFilled =
            bankName && routingNumber && accountNumber && accountType && accountOwner;

        if (!(isCreditCardFilled || isBankInfoFilled)) {
            alert("Please fill out either credit card or bank information.");
            return;
        }

        try {
            const pdfUrl = await generateAndSavePDF();
            if (!pdfUrl) {
                alert("Failed to upload PDF. Please try again.");
                return;
            }

            const signatureUrl = signature ? await uploadSignatureToDigitalOcean(signature) : null;
            if (!signatureUrl) {
                alert("Failed to upload signature. Please try again.");
                return;
            }

            const payload = {
                file: pdfUrl,  // PDF stored in Digital Ocean
                signature: signatureUrl, // Signature stored in Digital Ocean
                creditCard: {  // Sending creditCard as a nested object
                    cardholderName,
                    cardNumber,
                    expirationDate,
                    cvv,
                    zipCode
                },
                bankInfo: {
                    bankName,
                    routingNumber,
                    accountNumber,
                    accountType,
                    accountOwner
                }
            };

            const response = await updateDocument(accessToken, payload, params.documentId);

            if (response.status === "success") {
                setIsDocumentSubmitted(true);
            } else {
                alert("Something went wrong, please try again.");
            }
        } catch (error) {
            alert("Something went wrong, please try again.");
        }
    };

    const hasErrors = () => {
        const isCreditCardInvalid =
            !creditCard.cardholderName.trim() ||
            !creditCard.cardNumber.trim() ||
            !creditCard.expirationDate.trim() ||
            !creditCard.cvv.trim() ||
            !creditCard.zipCode.trim();

        const isBankAccountInvalid =
            !bankInfo.bankName.trim() ||
            !bankInfo.routingNumber.trim() ||
            !bankInfo.accountNumber.trim() ||
            !bankInfo.accountType.trim() ||
            !bankInfo.accountOwner.trim();

        const isSignatureMissing = !savedSignature;

        // If either the credit card fields + signature are valid, or bank fields + signature are valid, enable the button
        const isCreditCardValid = !isCreditCardInvalid && !isSignatureMissing;
        const isBankAccountValid = !isBankAccountInvalid && !isSignatureMissing;

        return (
            Object.values(errors).some((error) => error) ||  // Check for any errors
            !(isCreditCardValid || isBankAccountValid)  // Either credit card + signature or bank account + signature must be valid
        );
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-5">
            <div className="bg-white shadow-lg rounded-lg w-full max-w-7xl p-6 mb-10">
                <div className="items-center">
                    <div className="">
                        <img className="mx-auto mt-4 mb-4 h-auto w-36" src={"https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch%20Logo%20Horizontal%20w_tag%20green.png"} alt=""></img>
                    </div>
                </div>
                <h2 className="text-4xl font-semibold mb-6 text-center">{getDocumentTypeLabel(document.documentType)}</h2>
                <div className="space-y-6 mb-6">
                    <div className="text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-success-800">
                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Location:</h3>
                            {document?.meetingLocation ? (
                                <p className="text-center">
                                    {getMarketLabel(document.meetingLocation.market)} - {" "}
                                    {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} - {" "}
                                    {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                                </p>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Time:</h3>
                            {document.meetingStart && document.meetingEnd ? (
                                <>
                                    <p>
                                        {formatTime(document.meetingStart)} - {formatTime(document.meetingEnd)}
                                    </p>
                                </>
                            ) : (
                                <p>Invalid Meeting Time</p>
                            )}
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Date:</h3>
                            <p>{new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Rate (per Hour):</h3>
                            <p>${document.rate}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Company Name:</h3>
                            <p>{document.companyName}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Primary Phone:</h3>
                            <p>{document.primaryPhone}</p>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {termsContent.map((term, index) => (
                            <div key={index} className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <h4 className="font-semibold text-lg">{term.header}</h4>
                                {term.content.map((item, idx) => {
                                    if (Array.isArray(item)) {
                                        return (
                                            <ul key={idx} className="list-disc pl-6">
                                                {item.map((point, pIdx) => (
                                                    <li key={pIdx} className="text-gray-600">{point}</li>
                                                ))}
                                            </ul>
                                        );
                                    } else {
                                        return <p key={idx} className="text-gray-600">{item}</p>;
                                    }
                                })}
                            </div>
                        ))}
                    </div>

                    {/* Payment Information Section */}
                    <div className="space-y-4">
                        {document?.signature ? (
                            // Show the data fields (credit card or bank info) if signature is available
                            <>
                                {/* Displaying payment data */}
                                {document.creditCard.cardholderName && (
                                    <div className="max-w-4xl mx-auto p-8 bg-gray-50 rounded-lg shadow-md mb-6">
                                        {/* Credit Card Information Title */}

                                        <div className="text-center mb-6">
                                            <h3 className="text-3xl font-semibold text-gray-800 flex items-center justify-center gap-2">
                                                Credit Card Information
                                            </h3>
                                        </div>


                                        {/* Cardholder Name and Card Number */}
                                        <div className="text-center flex flex-col md:flex-row gap-6 mb-6">
                                            {document.creditCard.cardholderName && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Cardholder Name</h4>
                                                    <p className="text-gray-600">{document.creditCard.cardholderName}</p>
                                                </div>
                                            )}
                                            {document.creditCard.cardNumber && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Card Number</h4>
                                                    <p className="text-gray-600">{document.creditCard.cardNumber}</p>
                                                </div>
                                            )}
                                        </div>

                                        {/* Expiration Date, CVV, and ZIP Code */}
                                        <div className="text-center flex flex-wrap gap-6 mb-6">
                                            {document.creditCard.expirationDate && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Expiration Date</h4>
                                                    <p className="text-gray-600">{document.creditCard.expirationDate}</p>
                                                </div>
                                            )}
                                            {document.creditCard.cvv && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">CVV</h4>
                                                    <p className="text-gray-600">{document.creditCard.cvv}</p>
                                                </div>
                                            )}
                                            {document.creditCard.zipCode && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">ZIP Code</h4>
                                                    <p className="text-gray-600">{document.creditCard.zipCode}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {document?.bankInfo.bankName && (
                                    <div className="max-w-4xl mx-auto p-8 bg-gray-50 rounded-lg shadow-md mb-6">
                                        {/* Bank Information Title */}
                                        <div className="text-center mb-6">
                                            <h3 className="text-3xl font-semibold text-gray-800 flex items-center justify-center gap-2">
                                                Bank Information
                                            </h3>
                                        </div>

                                        {/* Bank Name and Account Info */}
                                        <div className="text-center flex flex-wrap gap-6 mb-6">
                                            {document.bankInfo.bankName && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Bank Name</h4>
                                                    <p className="text-gray-600">{document.bankInfo.bankName}</p>
                                                </div>
                                            )}
                                            {document.bankInfo.routingNumber && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Routing Number</h4>
                                                    <p className="text-gray-600">{document.bankInfo.routingNumber}</p>
                                                </div>
                                            )}
                                            {document.bankInfo.accountNumber && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Account Number</h4>
                                                    <p className="text-gray-600">{document.bankInfo.accountNumber}</p>
                                                </div>
                                            )}
                                        </div>

                                        {/* Account Type and Owner Info */}
                                        <div className="text-center flex flex-col md:flex-row gap-6 mb-6">
                                            {document.bankInfo.accountType && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Account Type</h4>
                                                    <p className="text-gray-600">{document.bankInfo.accountType}</p>
                                                </div>
                                            )}
                                            {document.bankInfo.accountOwner && (
                                                <div className="flex-1">
                                                    <h4 className="text-lg font-semibold text-gray-700">Account Owner</h4>
                                                    <p className="text-gray-600">{document.bankInfo.accountOwner}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>

                        ) : (
                            // Show the radio buttons and payment form if no signature is available
                            <>
                                <h3 className="text-2xl font-semibold text-center">Payment Information</h3>
                                <div className="flex justify-center space-x-6 mb-6">
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="credit-card"
                                            name="paymentType"
                                            value="creditCard"
                                            checked={paymentType === "creditCard"}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="credit-card" className="font-medium">Credit Card</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="ach"
                                            name="paymentType"
                                            value="ach"
                                            checked={paymentType === "ach"}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="ach" className="font-medium">ACH/Bank Info</label>
                                    </div>
                                </div>
                                {paymentType === "creditCard" ? (
                                    <div className="space-y-4">
                                        {/* Cardholder Name */}
                                        <div className="flex flex-col">
                                            <label htmlFor="cardholder-name" className="font-medium">
                                                Cardholder Name (as shown on card):
                                            </label>
                                            <input
                                                type="text"
                                                id="cardholder-name"
                                                name="cardholderName"
                                                className="p-2 border border-gray-300 rounded-md"
                                                value={creditCard.cardholderName}
                                                onChange={(e) => {
                                                    setCreditCard({ ...creditCard, cardholderName: e.target.value });
                                                    validateField(e.target.name, e.target.value);
                                                }}
                                                onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                required
                                            />
                                            {errors.cardholderName && <p className="text-red-500 text-sm">{errors.cardholderName}</p>}
                                        </div>

                                        {/* Card Number */}
                                        <div className="flex flex-col">
                                            <label htmlFor="card-number" className="font-medium">Card Number:</label>
                                            <input
                                                type="text"
                                                id="card-number"
                                                name="cardNumber"
                                                className="p-2 border border-gray-300 rounded-md"
                                                value={creditCard.cardNumber}
                                                onChange={(e) => {
                                                    setCreditCard({ ...creditCard, cardNumber: e.target.value });
                                                    validateField(e.target.name, e.target.value);
                                                }}
                                                onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                required
                                            />
                                            {errors.cardNumber && <p className="text-red-500 text-sm">{errors.cardNumber}</p>}
                                        </div>

                                        <div className="flex space-x-4">
                                            {/* Expiration Date */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="expiration-date" className="font-medium">Expiration Date (MM/YY):</label>
                                                <input
                                                    type="text"
                                                    id="expiration-date"
                                                    name="expirationDate"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={creditCard.expirationDate}
                                                    onChange={(e) => {
                                                        setCreditCard({ ...creditCard, expirationDate: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                />
                                                {errors.expirationDate && <p className="text-red-500 text-sm">{errors.expirationDate}</p>}
                                            </div>

                                            {/* CVV */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="cvv" className="font-medium">CVV:</label>
                                                <input
                                                    type="text"
                                                    id="cvv"
                                                    name="cvv"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={creditCard.cvv}
                                                    onChange={(e) => {
                                                        setCreditCard({ ...creditCard, cvv: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                />
                                                {errors.cvv && <p className="text-red-500 text-sm">{errors.cvv}</p>}
                                            </div>

                                        </div>

                                        {/* ZIP Code */}
                                        <div className="flex flex-col">
                                            <label htmlFor="zip-code" className="font-medium">Cardholder ZIP Code (Billing Address):</label>
                                            <input
                                                type="text"
                                                id="zip-code"
                                                name="zipCode"
                                                className="p-2 border border-gray-300 rounded-md"
                                                value={creditCard.zipCode}
                                                onChange={(e) => {
                                                    setCreditCard({ ...creditCard, zipCode: e.target.value });
                                                    validateField(e.target.name, e.target.value);
                                                }}
                                                onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                required
                                            />
                                            {errors.zipCode && <p className="text-red-500 text-sm">{errors.zipCode}</p>}
                                        </div>

                                    </div>
                                ) : paymentType === "ach" ? (
                                    <div className="space-y-4">
                                        {/* ACH/Bank Account Info */}
                                        <div className="flex flex-col">
                                            <label htmlFor="bank-account-name" className="font-medium">Bank Name:</label>
                                            <input
                                                type="text"
                                                id="bank-account-name"
                                                name="bankAccountName"
                                                className="p-2 border border-gray-300 rounded-md"
                                                value={bankInfo.bankName}
                                                onChange={(e) => {
                                                    setBankInfo({ ...bankInfo, bankName: e.target.value });
                                                    validateField(e.target.name, e.target.value);
                                                }}
                                                onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                required
                                            />
                                            {errors.bankAccountName && <p className="text-red-500 text-sm">{errors.bankAccountName}</p>}
                                        </div>

                                        <div className="flex space-x-4">
                                            {/* Routing Number */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="routing-number" className="font-medium">Routing Number:</label>
                                                <input
                                                    type="text"
                                                    id="routing-number"
                                                    name="routingNumber"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={bankInfo.routingNumber}
                                                    onChange={(e) => {
                                                        setBankInfo({ ...bankInfo, routingNumber: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                />
                                                {errors.routingNumber && <p className="text-red-500 text-sm">{errors.routingNumber}</p>}
                                            </div>

                                            {/* Account Number */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="account-number" className="font-medium">Account Number:</label>
                                                <input
                                                    type="text"
                                                    id="account-number"
                                                    name="accountNumber"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={bankInfo.accountNumber}
                                                    onChange={(e) => {
                                                        setBankInfo({ ...bankInfo, accountNumber: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                />
                                                {errors.accountNumber && <p className="text-red-500 text-sm">{errors.accountNumber}</p>}
                                            </div>

                                            {/* Type of Account (Savings, Checking) */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="account-type" className="font-medium">Type of Account:</label>
                                                <select
                                                    id="account-type"
                                                    name="accountType"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={bankInfo.accountType}
                                                    onChange={(e) => {
                                                        setBankInfo({ ...bankInfo, accountType: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select Account Type</option>
                                                    <option value="checking">Checking</option>
                                                    <option value="savings">Savings</option>
                                                </select>
                                                {errors.accountType && <p className="text-red-500 text-sm">{errors.accountType}</p>}
                                            </div>

                                            {/* Individual or Business Account */}
                                            <div className="flex flex-col flex-1">
                                                <label htmlFor="account-owner" className="font-medium">Account Owner Type:</label>
                                                <select
                                                    id="account-owner"
                                                    name="accountOwner"
                                                    className="p-2 border border-gray-300 rounded-md"
                                                    value={bankInfo.accountOwner}
                                                    onChange={(e) => {
                                                        setBankInfo({ ...bankInfo, accountOwner: e.target.value });
                                                        validateField(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select Account Owner</option>
                                                    <option value="individual">Individual</option>
                                                    <option value="business">Business</option>
                                                </select>
                                                {errors.accountOwner && <p className="text-red-500 text-sm">{errors.accountOwner}</p>}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>

                    <div className="flex flex-col sm:flex-row sm:gap-8 justify-left items-start"> {/* Aligning items at the top */}
                        {/* Signature Canvas Section */}
                        <div className="flex-1 sm:w-[55%]">
                            {document?.signature ? (
                                // If a signature is fetched, display it as an image preview
                                <div className="sm:w-[40%] mt-4 sm:mt-0 text-center sm:text-left self-start">
                                    <h4 className="text-lg font-semibold mb-2">Signature Preview</h4>
                                    <img
                                        src={document.signature}
                                        alt="Signature Preview"
                                        className="border border-gray-500 p-2 max-w-full h-auto mx-auto sm:mx-0"
                                    />
                                </div>
                            ) : (
                                // If no signature is fetched, show the signature canvas for the user to draw
                                <>
                                    <div className="relative mb-4">
                                        <SignatureCanvas
                                            ref={signaturePadRef}
                                            canvasProps={{
                                                width: 400,
                                                height: 150,
                                                className: "border border-gray-400 w-full sm:w-[400px] h-[150px] sm:h-[200px] mx-auto",
                                            }}
                                        />
                                    </div>
                                    <div className="flex justify-center gap-4 mb-4">
                                        <button
                                            onClick={clearSignature}
                                            className="bg-danger-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition"
                                        >
                                            Clear
                                        </button>
                                        <button
                                            onClick={saveSignature}
                                            className="bg-primary-400 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
                                        >
                                            Save Signature
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Signature Preview Section */}
                        {savedSignature && (
                            <div className="sm:w-[40%] mt-4 sm:mt-0 text-center sm:text-left self-start"> {/* Aligning the preview at the top */}
                                <h4 className="text-lg font-semibold mb-2">Signature Preview</h4>
                                <img
                                    src={savedSignature}
                                    alt="Signature Preview"
                                    className="border border-gray-500 p-2 max-w-full h-auto mx-auto sm:mx-0"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* Always Show Submit Button */}
                {
                    !document?.signature ? (
                        <button
                            onClick={handleSubmit}
                            className={`bg-primary-400 text-white py-2 px-6 rounded-md hover:bg-green-700 transition duration-300 mt-6 ${hasErrors() ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={hasErrors()}
                        >
                            Submit Document
                        </button>
                    ) : null
                }

                {/* Thank You Popup */}
                {isDocumentSubmitted && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg text-center">
                            <h2 className="text-2xl font-semibold">Thank You!</h2>
                            <p>Your document has been successfully submitted.</p>
                            <button
                                onClick={() => {
                                    setIsDocumentSubmitted(false); // Close the popup
                                    window.location.reload(); // Reload the page
                                }}
                                className="mt-4 bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700 transition duration-300"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
};

export default CCAuthorizationDoc;
