/* eslint-disable no-unused-vars */
import React from "react";
import Footer from "../../components/footer/Footer";
import HomeNavigation from "../../components/home_navigation/HomeNavigation";
import { Link } from "react-router-dom";
import NewsLetter from "../../components/newsletter/NewsLetter";
import useScrollPage from "../../hooks/useScrollPage";
import HomeFeatures from "../../components/home_features/HomeFeatures";
import PricingCta from "../../components/pricing_cta/PricingCta";
import Popup from "reactjs-popup";
import ExplainerVideo from "../../components/video/ExplainerVideo";
import useTitle from "../../hooks/useTitle";
import { FaWindowClose } from "react-icons/fa";
import Explainer from "../../assets/COhatch+Explainer.mp4";

function Home() {
	useScrollPage();
	useTitle("COhatch+");

	return (
		<>
			<HomeNavigation />
			<header className="relative h-[600px] w-full font-bebas flex items-center justify-center">
				<video
					className="absolute top-0 left-0 h-full w-full object-cover -z-10"
					autoPlay
					loop
					muted
					preload="auto"
					playsInline
				>
					<source src="https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/videos/background_video.mp4"></source>
					Your browser does not support this video format.
				</video>
				<div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 -z-10"></div>
				<div className="z-20 flex flex-col">
					<h1 className="text-center font-bebas text-4xl sm:text-5xl lg:text-7xl ">
						<span className="block text-white">
							MEET <span className="text-primary-500">COhatch+</span>
						</span>
					</h1>
					<p className="mt-6 max-w-lg mx-auto text-center font-roboto font-bold text-xl text-white sm:max-w-3xl">
						Helping you Work, Meet, and Live digitally across our growing
						community.
					</p>

					<div className="mt-6 px-3 space-y-4 sm:space-y-0 sm:mx-auto  sm:gap-5">
						<ExplainerVideo
							button={
								<a className="flex items-center justify-center px-4 py-3 border border-transparent text-xl font-bold rounded-md shadow-sm text-white bg-primary-500 bg-opacity-100 hover:bg-opacity-70 sm:px-8 transition ease-linear duration-500">
									What is COhatch+ ?
								</a>
							}
						/>
					</div>
				</div>
			</header>

			<section className="max-w-7xl mx-auto p-5 md:p-20">
				<h1 className="text-primary-400 text-center text-2xl font-extrabold sm:text-5xl lg:text-5xl">
					<span className="block font-bebas">Everything You Need To</span>
					<span className="block font-bebas">
						Work, Meet, And Live In One Place
					</span>
				</h1>
				<div className="mt-10 mb-10"></div>
				<p className="mt-4 text-center text-base md:text-xl text-secondary-500">
					At COhatch, we're always striving to give our members everything they
					need to work, meet, and live. Through COhatch+, we are bringing
					everything that makes our physical locations great into one digital
					platform for more accessible and fun everyday life.
				</p>
			</section>

			{/* <div id="home_features">
				<HomeFeatures />
			</div> */}

			{/* <div className=" w-7xl h-1/2">
				<PricingCta />
			</div> */}

			<section className="font-roboto">
				<section className="w-full font-roboto pr-20 pl-20">
					<NewsLetter />
				</section>
				<Footer />
			</section>
		</>
	);
}

export default Home;
