/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function getUserDocuments(accessToken, userId, page, limit) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/document/mydocuments/?page=${page}&limit=${limit}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getUserDocuments;
