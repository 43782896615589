/* eslint-disable no-magic-numbers */
/* eslint-disable */
import { City, State } from "country-state-city";
import {
	GiTowel,
	GiComb,
	GiPillow,
	GiWashingMachine,
	GiSpiralBottle,
	GiCableStayedBridge,
	GiPingPongBat,
	GiPoolTableCorner,
	GiConsoleController,
	GiDesk,
	GiGasStove,
	GiToaster,
	GiCookingPot,
	GiTeapotLeaves,
	GiCctvCamera,
	GiFirstAidKit,
	GiGate,
	GiThermometerCold,
	GiThermometerHot,
	GiFireplace,
	GiWoodenChair,
	GiHomeGarage,
	GiDoorway,
	GiSofa,
	GiMountains,
	GiShower,
	GiPartyPopper,
	GiWoodenDoor,
} from "react-icons/gi";
import { TbBottle, TbHanger, TbApps, TbBooks, TbBeach } from "react-icons/tb";
import {
	FaBath,
	FaShower,
	FaTv,
	FaWifi,
	FaUtensils,
	FaFireExtinguisher,
	FaFan,
	FaBox,
	FaParking,
	FaUmbrellaBeach,
	FaSwimmingPool,
	FaHotTub,
	FaWater,
	FaWheelchair,
	FaChild,
} from "react-icons/fa";
import { BiBlanket, BiCloset } from "react-icons/bi";
import {
	MdIron,
	MdLocalLaundryService,
	MdSpeaker,
	MdMicrowave,
	MdOutlineMicrowave,
	MdOutlineCoffeeMaker,
	MdDining,
	MdCrib,
	MdElectricCar,
	MdFence,
	MdOutdoorGrill,
	MdBeachAccess,
	MdBalcony,
	MdWater,
	MdElevator,
	MdPets,
} from "react-icons/md";
import {
	CgSmartHomeRefrigerator,
	CgSmartHomeWashMachine,
} from "react-icons/cg";
import { RiAlarmWarningFill } from "react-icons/ri";
import { IoMdBonfire } from "react-icons/io";
import { GrLounge } from "react-icons/gr";

export const libraries = ["places"];
//List of business category, subcategory, amenities, color and summary for the listing form and listing page

export const BUSINESS_CATEGORY = {
	business_services: {
		mainCategory: { value: "business_services", label: "Business Services" },
		subCategory: [
			{ value: "marketing", label: "Marketing" },
			{ value: "graphic_design", label: "Graphic Design" },
			{
				value: "Web_design_and_development",
				label: "Web Design and Development",
			},
			{
				value: "printing_and_merchandising",
				label: "Printing and Merchandising",
			},
			{
				value: "hospitality_and_event_planning",
				label: "Hospitality and Event Planning",
			},
			{ value: "training_and_development", label: "Training + Development" },
			{
				value: "human_resource_and_staffing",
				label: "Human Resource and Staffing",
			},
			{ value: "insurance", label: "Insurance" },
			{ value: "legal", label: "Legal" },
			{ value: "finance", label: "Finance" },
			{ value: "landscaping", label: "Landscaping" },
		],
		amenities: [{ value: "free_consultation", label: "Free Consultation" }],
		color: "emerald-500",
		summary:
			"Support local businesses. Shop direct and save with local offers.",
	},
	home_services: {
		mainCategory: { value: "home_services", label: "Home + Personal Services" },
		subCategory: [
			{
				value: "landscaping_and_lawn_services",
				label: "Landscaping and Lawn Services",
			},
			{ value: "construction", label: "Construction" },
			{ value: "plumbing_hvac", label: "Plumbing and HVAC" },
			{ value: "electricians", label: "Electricians" },
			{ value: "car_services", label: "Car Services" },
			{ value: "security", label: "Security" },
			{ value: "telecommunication", label: "Telecommunication" },
			{ value: "travel_planning_agency", label: "Travel Planning Agency" },
			{ value: "real_estate", label: "Real Estate" },
			{ value: "interior_design", label: "Interior Design" },
			{ value: "health_and_wellness", label: "Health and Wellness" },
		],
		amenities: [{ value: "free_consultation", label: "Free Consultation" }],
		color: "slate-600",
		summary:
			"Find what you need locally. No hidden fees or dollars go to us when you choose a service.",
	},
	travel: {
		mainCategory: { value: "travel", label: "Travel" },
		subCategory: [
			{ value: "hotels_and_resorts", label: "Hotels and Resorts" },
			{ value: "travel_planning", label: "Travel Planning" },
			{ value: "transportation", label: "Transportation" },
			{ value: "tours_and_experiences", label: "Tours + Experiences" },
		],
		amenities: [
			{ value: "virtual_appointments", label: "Virtual Appointments" },
			{ value: "virtual_booking", label: "Virtual Booking" },
		],
		color: "slate-600",
		summary:
			"Browse exclusive travel deals for your next big adventure. Get deals at local hotels, gather advice from our handpicked experts, and book unique experiences, all while supporting local!",
	},
	shops: {
		mainCategory: { value: "shops", label: "Shops" },
		subCategory: [
			{ value: "apparel_and_shoes", label: "Apparel and Shoes" },
			{
				value: "electronics_and_appliances",
				label: "Electronics and Appliances",
			},
			{ value: "health_and_beauty", label: "Health and Beauty" },
			{ value: "furniture", label: "Furniture" },
			{ value: "home_goods", label: "Home Goods" },
			{ value: "arts_and_craft", label: "Arts and Craft" },
			{ value: "baby_and_kits", label: "Baby and Kids" },
			{ value: "books_and_music", label: "Books and Music" },
			{ value: "pet_supplies", label: "Pet Supplies" },
			{ value: "sports_and_outdoors", label: "Sports and Outdoors" },
		],
		amenities: [
			{ value: "delivery", label: "Deliver" },
			{ value: "store_pickup", label: "Store Pickup" },
			{ value: "shipping", label: "Shipping" },
		],
		color: "red-500",
		summary:
			"Shop small at your local retail stores. Buy direct and save with our local discounts.",
	},
	startups: {
		mainCategory: { value: "startups", label: "Startups" },
		subCategory: [
			{ value: "technology", label: "Technology" },
			{ value: "social", label: "Social" },
			{ value: "eCommerce", label: "eCommerce" },
			{ value: "fintech", label: "Fintech" },
			{ value: "healthtech", label: "HealthTech " },
			{ value: "saas", label: "SaaS" },
			{ value: "biotech", label: "Biotech" },
			{ value: "education", label: "Education" },
		],

		color: "emerald-500",
		summary: "Startup",
	},
	makers_and_artisans: {
		mainCategory: { value: "makers_and_artisans", label: "Makers + Artisans" },
		subCategory: [
			{ value: "apparel_and_shoes", label: "Apparel and Shoes" },
			{
				value: "electronics_and_appliances",
				label: "Electronics and Appliances",
			},
			{ value: "health_and_beauty", label: "Health and Beauty" },
			{ value: "furniture", label: "Furniture" },
			{ value: "home_goods", label: "Home Goods" },
			{ value: "arts_and_craft", label: "Arts and Craft" },
			{ value: "baby_and_kids", label: "Baby and Kids" },
			{ value: "books_and_music", label: "Books and Music" },
			{ value: "pet_supplies", label: "Pet Supplies" },
			{ value: "sports_and_outdoors", label: "Sports and Outdoors" },
			{ value: "leather_and_wood_work", label: "Leather and Woodwork" },
		],
		amenities: [
			{ value: "delivery", label: "Deliver" },
			{ value: "local_pickup", label: "Local Pickup" },
			{ value: "shipping", label: "Shipping" },
		],
		color: "orange-900",
		summary:
			"Support local makers and artisans with no extra costs or hidden fees.",
	},

	restaurants: {
		mainCategory: { value: "restaurants", label: "Restaurants" },
		subCategory: [
			{ value: "mexican", label: "Mexican" },
			{ value: "indian", label: "Indian" },
			{ value: "thai", label: "Thai" },
			{ value: "american", label: "American" },
			{ value: "brewery_pub", label: "Brewery/Pub" },
			{ value: "Mediterranean", label: "Mediterranean" },
			{ value: "italian", label: "Italian" },
			{ value: "asian", label: "Asian" },
			{ value: "fast_food_and_street_food", label: "Fast Food and Street" },
			{ value: "deli", label: "Deli" },
			{ value: "vegan_vegetarian", label: "Vegan/Vegetarian" },
			{ value: "middle_east", label: "Middle East" },
			{ value: "bakery", label: "Bakery" },
			{ value: "coffee_shop", label: "Coffee Shop" },
		],
		amenities: [
			{ value: "delivery", label: "Deliver" },
			{ value: "outdoor_seating", label: "Outdoor Seating" },
			{ value: "free_wifi", label: "Free Wifi" },
			{
				value: "locally_sourced_ingredients",
				label: "Locally Sourced Ingredient",
			},
			{ value: "private_room", label: "Private Room" },
			{ value: "holiday_menu", label: "Holiday Menu" },
			{ value: "catering", label: "Catering" },
			{ value: "dog_friendly", label: "Dog-Friendly" },
			{ value: "reservations", label: "Reservations" },
			{ value: "seasonal_menu", label: "Seasonal Menu" },
			{ value: "curb_side_pickup", label: "Curb Side Pickup" },
			{ value: "vegan", label: "Vegan" },
			{ value: "wheelchair_accessible", label: "Wheelchair Accessible" },
			{ value: "family_friendly", label: "Family Friendly" },
			{ value: "fire_place", label: "Fireplace" },
			{ value: "happy_hour", label: "Happy Hour" },
			{ value: "online_ordering", label: "Online Ordering" },
			{ value: "vegetarian", label: "Vegetarian" },
		],
		color: "lime-600",
		summary:
			"Supporting local restaurants has never been easier. Grab your discount and eat locally. ",
	},

	community_resources: {
		mainCategory: {
			value: "community_resources",
			label: "Community Resources",
		},
		subCategory: [
			{ value: "workspace", label: "Workspace" },
			{ value: "community_center", label: "Community Center" },
			{ value: "libraries", label: "Libraries" },
			{ value: "clubs_and_organizations", label: "Clubs and Organizations" },
			{ value: "business_accelerators", label: "Business Accelerators" },
			{ value: "business_resources", label: "Business Resources" },
		],
		amenities: [
			{ value: "game_rooms", label: "Game Rooms" },
			{ value: "meeting_rooms", label: "Meeting Rooms" },
			{ value: "fitness_areas", label: "Fitness Areas" },
			{ value: "event_space", label: "Event Space" },
			{ value: "free_business_resource", label: "Free Business Resource" },
			{ value: "wheelchair_accessible", label: "Wheelchair Accessible" },
		],
		color: "cyan-500",
		summary:
			"Find your community through our resources like workspaces, chambers, nonprofits, and more.",
	},
	business_resources: {
		mainCategory: { value: "business_resources", label: "Business Resources" },
		subCategory: [
			{ value: "workspace", label: "Workspace" },
			{ value: "community_center", label: "Community Center" },
			{ value: "libraries", label: "Libraries" },
			{ value: "clubs_and_organizations", label: "Clubs and Organizations" },
			{ value: "business_accelerators", label: "Business Accelerators" },
			{ value: "business_resources", label: "Business Resources" },
		],
		amenities: [
			{ value: "game_rooms", label: "Game Rooms" },
			{ value: "meeting_rooms", label: "Meeting Rooms" },
			{ value: "fitness_areas", label: "Fitness Areas" },
			{ value: "event_space", label: "Event Space" },
			{ value: "free_business_resource", label: "Free Business Resource" },
			{ value: "wheelchair_accessible", label: "Wheelchair Accessible" },
		],
		color: "emerald-500",
		summary:
			"Support local businesses. Shop direct and save with local offices.",
	},
	chambers: {
		mainCategory: { value: "chambers", label: "Chambers" },
		subCategory: [
			{ value: "workspace", label: "Workspace" },
			{ value: "community_center", label: "Community Center" },
			{ value: "libraries", label: "Libraries" },
			{ value: "clubs_and_organizations", label: "Clubs and Organizations" },
			{ value: "business_accelerators", label: "Business Accelerators" },
			{ value: "business_resources", label: "Business Resources" },
		],
		amenities: [
			{ value: "game_rooms", label: "Game Rooms" },
			{ value: "meeting_rooms", label: "Meeting Rooms" },
			{ value: "fitness_areas", label: "Fitness Areas" },
			{ value: "event_space", label: "Event Space" },
			{ value: "free_business_resource", label: "Free Business Resource" },
			{ value: "wheelchair_accessible", label: "Wheelchair Accessible" },
		],
		color: "emerald-500",
		summary:
			"Support local businesses. Shop direct and save with local offices.",
	},
	non_profits: {
		mainCategory: { value: "non_profits", label: "Local Nonprofits" },
		subCategory: [
			{ value: "animals", label: "Animals" },
			{ value: "arts_culture_humanity", label: "Arts Culture and Humanity" },
			{ value: "climate", label: "Climate" },
			{ value: "community_development", label: "Community Development" },
			{ value: "conservation", label: "Conservation" },
			{ value: "consumption", label: "Consumption" },
			{ value: "crisis", label: "Crisis" },
			{ value: "education", label: "Education" },
			{ value: "energy", label: "Energy" },
			{ value: "equality", label: "Equality" },
			{ value: "family", label: "Family" },
			{ value: "food", label: "Food" },
			{ value: "health", label: "Health" },
			{ value: "homeless", label: "Homeless" },
			{ value: "jobs", label: "Jobs" },
			{ value: "other", label: "Other" },
			{ value: "peace_and_justice", label: "Peace and Justice" },
			{ value: "poverty", label: "Poverty" },
			{ value: "refugees", label: "Refugees" },
			{ value: "religious", label: "Religious" },
			{ value: "water_and_sanitation", label: "Water and Sanitation" },
		],
		amenities: [
			{ value: "volunteer_opportunity", label: "Volunteer Opportunity" },
			{ value: "donations_accepted", label: "Donation Accepted" },
		],
		color: "indigo-500",
		summary: "Do more good by supporting nonprofits near you",
	},
	farmers_market: {
		mainCategory: { value: "farmers_market", label: "Farmers + Food " },
		subCategory: [
			{ value: "produce", label: "Produce" },
			{ value: "meat_and_eggs", label: "Meat and Eggs" },
			{ value: "dairy_cheese", label: "Dairy and Cheese" },
			{ value: "honey_and_syrup", label: "Honey and Syrup" },
			{ value: "flowers_and_garden", label: "Flowers and Garden" },
			{ value: "baked_goods", label: "Baked Goods" },
			{ value: "coffee_and_tea", label: "Coffee and Tea" },
			{ value: "beverages", label: "Beverages" },
			{ value: "prepared_food", label: "Prepared Food" },
			{ value: "arts_and_craft", label: "Arts and Craft" },
		],
		amenities: [
			{ value: "music", label: "Music" },
			{ value: "winter_market", label: "Winter Market" },
			{ value: "volunteers_opportunities", label: "Volunteers Opportunities" },
			{ value: "summer_market", label: "Summer Market" },
			{ value: "year_round_market", label: "Year Round Market" },
		],
		color: "blue-500",
		summary:
			"Local foods matter! Every dollar you spend goes directly to your producer.",
	},
};

const cities = City.getCitiesOfCountry("US");
const states = State.getStatesOfCountry("US");
const stateCities = {};

cities.forEach((city) => {
	const selectedState = states.find((st) => st.isoCode === city.stateCode);
	if (selectedState) {
		if (!stateCities[selectedState.name]) {
			stateCities[selectedState.name] = [];
		}
		stateCities[selectedState.name].push(city.name);
	}
});

export const STATES_CITIES = stateCities;

export const MAGIC_NUMBERS = {
	ZERO: 0,
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
	REVIEW_LENGTH: 1000,
	MESSAGE_LENGTH: 2500,
	BIO_LENGTH: 8000,
	PHONE_LENGTH: 10,
	MIN_PROFILE_PHOTO_SIZE: 1, //1MB
	MAX_PROFILE_PHOTO_SIZE: 5, //MB
	BYTE_TO_MB: 1e6,
	SERVER_ERROR_CODES: [404, 500],
};

//Types of Membership

export const PLANS = {
	prod_LDPFhHUXU9RMQu: "PREMIUM",
	prod_LDPbQHt87On0Df: "LOCAL DISCOUNT",
	prod_LUlsnnmDZeQZ3Q: "BASIC",
	prod_LUlwJ9OeNj1gKE: "COHATCH",
	prod_Rod3Hli28oZVQe: "DOCSIGNEE",
};

export const PRODUCT = {
	PREMIUM: "prod_La5g3PKJwMmwzK",
	BASIC: "prod_La5g7rXay2nqt3",
	FREE: "prod_La5fuMlkuQWIl0",
	COHATCH: "prod_La5fU3DTh1M2dr",
	DOCSIGNEE: "prod_Rod3Hli28oZVQe"
};

//Newsletter
export const NEWS_LETTER = {
	GENERAL_UPDATES: "GENERAL_UPDATES",
	NEW_FEATURES: "NEW_FEATURES",
	SECURITY_ALERT: "SECURITY_ALERT",
};
//Status
export const STATUS = {
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	REQUIRES_EMAIL_VERIFICATION: "REQUIRES_EMAIL_VERIFICATION",
	REQUIRES_SUBSCRIPTION: "REQUIRES_SUBSCRIPTION",
	SUBSCRIPTION_INACTIVE: "SUBSCRIPTION_INACTIVE",
};

//Reaction list for group post
export const REACTIONS = {
	LIKE: "LIKE",
	LOVE: "LOVE",
	HAHA: "HAHA",
	WOW: "WOW",
	SAD: "SAD",
	ANGRY: "ANGRY",
};

export const INTERESTS = [
	{ value: "football", label: "Football" },
	{ value: "basketball", label: "Basketball" },
	{ value: "volleyball", label: "Volleyball" },
	{ value: "cooking", label: "Cooking" },
	{ value: "marathonrunning", label: "Marathon Running" },
	{ value: "skiing", label: "Skiing" },
	{ value: "tennis", label: "Tennis" },
	{ value: "cycling", label: "Cycling" },
	{ value: "swimming", label: "Swimming" },
	{ value: "baseball", label: "Baseball" },
	{ value: "mountainclimbing", label: "Mountain Climbing" },
];

export const CAUSES = [
	{ value: "animals", label: "Animals" },
	{ value: "artscultureandhumanity", label: "Arts, Culture & Humanity" },
	{ value: "climate", label: "Climate" },
	{ value: "communitydevelopment", label: "Community Development" },
	{ value: "conservation", label: "Conservation" },
	{ value: "consumption", label: "Consumption" },
	{ value: "crisis", label: "Crisis" },
	{ value: "education", label: "Education" },
	{ value: "energy", label: "Energy" },
	{ value: "equality", label: "Equality" },
	{ value: "family", label: "Family" },
	{ value: "food", label: "Food" },
	{ value: "health", label: "Health" },
	{ value: "homeless", label: "Homeless" },
	{ value: "jobs", label: "Jobs" },
	{ value: "peaceandjustice", label: "Peace and Justice" },
	{ value: "poverty", label: "Poverty" },
	{ value: "refugees", label: "Refugees" },
	{ value: "religious", label: "Religious" },
	{ value: "waterandsanitation", label: "Water & Sanitation" },
];

export const DATE_OPTIONS = {
	weekday: "long",
	year: "numeric",
	month: "long",
	day: "numeric",
};
export const DATE_LOCALE = "en-US";

export const DEFAULT_PAGINATION_LIMIT = 10;
export const MAX_UPLOAD_SIZE = 10;

export const DEFAULT_MAP_OPTIONS = {
	mapId: "a0a654f664a096b7",
	mapTypeControl: false,
	panControl: false,
	navigationControl: false,
	scaleControl: false,
	scrollWheel: false,
	streetViewControl: false,
};
export const DEFAULT_MAP_ZOOM = 8;
export const DEFAULT_MAP_CENTER = { lat: 40.367474, lng: -82.996216 };

export const PRICE = [
	{ value: "$", label: "$" },
	{ value: "$$", label: "$$" },
	{ value: "$$$", label: "$$$" },
];

export const PAYMENT_METHODS = [
	{ value: "Credit Card", label: "Credit Card" },
	{ value: "ACH", label: "ACH" },
	{ value: "Cash", label: "Cash" },
	{ value: "Gift Card", label: "Gift Card" },
	{ value: "Apple Pay", label: "Apple Pay" },
	{ value: "Bank Check", label: "Bank Check" },
	{ value: "Coupons", label: "Coupons" },
];

//List of Cities and COhatch location names for each city
export const LOCATIONS = {
	columbus: [
		{ value: "delaware", label: "Delaware" },
		{ value: "easton", label: "Easton" },
		{ value: "upper arlingtion", label: "Upper Arlingtion" },
		{ value: "polaris", label: "Polaris" },
		{ value: "worthington-hardware", label: "Worthingtion (Hardware)" },
		{ value: "worthington-library", label: "Worthingtion (Library)" },
		{ value: "dublin", label: "Dublin" },
		{ value: "gateway", label: "Gateway" },
		{ value: "westerville", label: "Westerville" },
	],
	springfield: [{ value: "springfield", label: "Springfield" }],

	indianapolis: [
		{ value: "noblesville", label: "Noblesville" },
		{ value: "broad ripple", label: "Broad Ripple" },
		{ value: "zionsville", label: "Zionsville" },
		{ value: "downtown indy", label: "Downtown Indy" },
		{ value: "carmel", label: "Carmel" },
	],

	cincinnati: [
		{ value: "covington", label: "Covington" },
		{ value: "hyde park", label: "Hyde Park" },
		{ value: "mason", label: "Mason" },
		{ value: "milford", label: "Milford" },
		{ value: "kenwood", label: "Kenwood" },
	],

	cleveland: [
		{ value: "beachwood", label: "Beachwood" },
		{ value: "ohio city", label: "Ohio City" },
	],

	pittsburgh: [
		{ value: "shadyside", label: "Shadyside" },
		{ value: "southside works", label: "Southside Works" },
		{ value: "waterfront", label: "Waterfront" },
	],

	"Central Florida": [
		{ value: "lakeland", label: "Lakeland" },
		{ value: "st. petersburg", label: "St. Petersburg" },
		{ value: "tarpon_springs", label: "Tarpon Springs" },
		{ value: "west_tampa", label: "West Tampa " },
	],

	atlanta: [
		{ value: "duluth", label: "Duluth" },
		{ value: "lawrenceville", label: "Lawrenceville" },
	],
};

//ADDING FOR NOW >> WILL DELETE, goal > auto generate from data fetched from officeRnd offices endpoint
export const OFFICEID_NAME = {
	"5d1bcda0dbd6e40010479eec": "COhatch Mason",
	"5fd23f130ecc6d8a668543db": "COhatch Ohio City",
	"606af848e1fae5fe0aa1c2af": "COhatch Noblesville",
	"606af857c10359a5c9f3f6ed": "COhatch Hyde Park",
	"606af872e1fae54e2da1c315": "COhatch Springfield",
	"606af8810b1f05378ad799e7": "COhatch Upper Arlington",
	"606af88d5e43340c60c209b8": "COhatch Delaware",
	"606af89d5e433411eec209cf": "COhatch Easton",
	"606af8ab5e4334f28dc209e7": "COhatch Polaris",
	"606af8be5e43345791c20a01": "COhatch Worthington - The Library & The Madery",
	"606af8e161a5fbfe224c3002": "COhatch Worthington - The Hardware Store",
	"606af961262dad011aa7c154": "COhatch Broad Ripple",
	"606b0d3422b1003de911eea7": "COhatch Dublin",
	"606b0ea033fc9a7d5b53a1b7": "COhatch Milford",
	"606b10f2f7ff2fc987137673": "COhatch Beachwood",
	"606b14899ff4771341cc9201": "COhatch The Gateway",
	"60a1138d775d0f03eec14ee0": "COhatch Kenwood",
	"61097250b12e1486413b171a": "COhatch Zionsville",
	"6109728eb12e145cca3b1f59": "COhatch Circle Centre",
	"61435e994274f6b1e4d2bff8": "COhatch St. Petersburg",
	"61435f4e2a9271b93fb57221": "COhatch West Tampa",
	"61435fba63579c94b09276e5": "COhatch Lakeland",
	"61a4e2f49b30e7104f11278b": "COhatch Carmel",
	"61a502e6b36e570a87ac582f": "COhatch Findlay Market",
	"624f40390fd203233bb9b659": "COhatch Tarpon Springs",
	"624f4116bd2861034d10d844": "COhatch Polk Stables",
	"625675b22cec9a850f913a58": "COhatch Westerville",
	"6287bd60e608c53fd3fae8d3": "COhatch Waterfront",
	"6287bdc9fc1c6944183a059f": "COhatch Shadyside",
	"6287bdffe608c584f6faecb1": "COhatch Southside Works",
};

export const ID_OFFICENAME = [
	{ value: "606b10f2f7ff2fc987137673", label: "COhatch Beachwood" },
	{ value: "606af961262dad011aa7c154", label: "COhatch Broad Ripple" },
	{ value: "61a4e2f49b30e7104f11278b", label: "COhatch Carmel" },
	{ value: "6109728eb12e145cca3b1f59", label: "COhatch Circle Centre" },
	{ value: "606af88d5e43340c60c209b8", label: "COhatch Delaware" },
	{ value: "606b0d3422b1003de911eea7", label: "COhatch Dublin" },
	{ value: "606af89d5e433411eec209cf", label: "COhatch Easton" },
	{ value: "61a502e6b36e570a87ac582f", label: "COhatch Findlay Market" },
	{ value: "606af857c10359a5c9f3f6ed", label: "COhatch Hyde Park" },
	{ value: "60a1138d775d0f03eec14ee0", label: "COhatch Kenwood" },
	{ value: "61435fba63579c94b09276e5", label: "COhatch Lakeland" },
	{ value: "5d1bcda0dbd6e40010479eec", label: "COhatch Mason" },
	{ value: "606b0ea033fc9a7d5b53a1b7", label: "COhatch Milford" },
	{ value: "606af848e1fae5fe0aa1c2af", label: "COhatch Noblesville" },
	{ value: "5fd23f130ecc6d8a668543db", label: "COhatch Ohio City" },
	{ value: "606af8ab5e4334f28dc209e7", label: "COhatch Polaris" },
	{ value: "624f4116bd2861034d10d844", label: "COhatch Polk Stables" },
	{ value: "6287bdc9fc1c6944183a059f", label: "COhatch Shadyside" },
	{ value: "6287bdffe608c584f6faecb1", label: "COhatch Southside Works" },
	{ value: "606af872e1fae54e2da1c315", label: "COhatch Springfield" },
	{ value: "61435e994274f6b1e4d2bff8", label: "COhatch St. Petersburg" },
	{ value: "624f40390fd203233bb9b659", label: "COhatch Tarpon Springs" },
	{ value: "606b14899ff4771341cc9201", label: "COhatch The Gateway" },
	{ value: "606af8810b1f05378ad799e7", label: "COhatch Upper Arlington" },
	{ value: "6287bd60e608c53fd3fae8d3", label: "COhatch Waterfront" },
	{ value: "61435f4e2a9271b93fb57221", label: "COhatch West Tampa" },
	{ value: "625675b22cec9a850f913a58", label: "COhatch Westerville" },
	{
		value: "606af8e161a5fbfe224c3002",
		label: "COhatch Worthington - The Hardware Store",
	},
	{
		value: "606af8be5e43345791c20a01",
		label: "COhatch Worthington - The Library & The Madery",
	},
	{ value: "61097250b12e1486413b171a", label: "COhatch Zionsville" },
];

export const FREE_LIFE_EVENTS_OFFICES = [
	{ value: "all", label: "All Locations" },
	{
		value: "5d1bcda0dbd6e40010479eec",
		label: "COhatch Mason",
		city: "Mason",
		state: "Ohio",
	},
	{
		value: "5fd23f130ecc6d8a668543db",
		label: "COhatch Ohio City",
		city: "Cleveland",
		state: "Ohio",
	},
	{
		value: "606af848e1fae5fe0aa1c2af",
		label: "COhatch Noblesville",
		city: "Noblesville",
		state: "Indiana",
	},
	{
		value: "606af857c10359a5c9f3f6ed",
		label: "COhatch Hyde Park",
		city: "Cincinnati",
		state: "Ohio",
	},
	{
		value: "606af872e1fae54e2da1c315",
		label: "COhatch Springfield",
		city: "Springfield",
		state: "Ohio",
	},
	{ value: "606af8810b1f05378ad799e7", label: "COhatch Upper Arlington" },
	{ value: "606af88d5e43340c60c209b8", label: "COhatch Delaware" },
	{ value: "606af89d5e433411eec209cf", label: "COhatch Easton" },
	{ value: "606af8ab5e4334f28dc209e7", label: "COhatch Polaris" },
	{
		value: "606af8be5e43345791c20a01",
		label: "COhatch Worthington - The Library & The Madery",
	},
	{
		value: "606af8e161a5fbfe224c3002",
		label: "COhatch Worthington - The Hardware Store",
	},
	{ value: "606af961262dad011aa7c154", label: "COhatch Broad Ripple" },
	{ value: "606b0d3422b1003de911eea7", label: "COhatch Dublin" },
	{ value: "606b0ea033fc9a7d5b53a1b7", label: "COhatch Milford" },
	{
		value: "606b10f2f7ff2fc987137673",
		label: "COhatch Beachwood",
		city: "Beachwood",
		state: "Ohio",
	},
	{ value: "606b14899ff4771341cc9201", label: "COhatch The Gateway" },
	{ value: "60a1138d775d0f03eec14ee0", label: "COhatch Kenwood" },
	{ value: "61097250b12e1486413b171a", label: "COhatch Zionsville" },
	{ value: "6109728eb12e145cca3b1f59", label: "COhatch Circle Centre" },
	{
		value: "61435e994274f6b1e4d2bff8",
		label: "COhatch St. Petersburg",
		city: "St. Petersburg",
		state: "Florida",
	},
	{ value: "61435fba63579c94b09276e5", label: "COhatch Lakeland" },
	{ value: "61a4e2f49b30e7104f11278b", label: "COhatch Carmel" },
	{ value: "6287bd60e608c53fd3fae8d3", label: "COhatch Waterfront" },
];

export const AMENITY_OFFICES = [
	{ value: "all", label: "All Locations" },
	{
		value: "5d1bcda0dbd6e40010479eec",
		label: "COhatch Mason",
		city: "Mason",
		state: "Ohio",
	},
	{ value: "606af88d5e43340c60c209b8", label: "COhatch Delaware" },
	{ value: "606af89d5e433411eec209cf", label: "COhatch Easton" },
	{ value: "606af8ab5e4334f28dc209e7", label: "COhatch Polaris" },
	{
		value: "606af8be5e43345791c20a01",
		label: "COhatch Worthington - The Library & The Madery",
	},
	{
		value: "606af8e161a5fbfe224c3002",
		label: "COhatch Worthington - The Hardware Store",
	},
	{ value: "606af961262dad011aa7c154", label: "COhatch Broad Ripple" },
	{ value: "606b0ea033fc9a7d5b53a1b7", label: "COhatch Milford" },
	{
		value: "606b10f2f7ff2fc987137673",
		label: "COhatch Beachwood",
		city: "Beachwood",
		state: "Ohio",
	},
	{ value: "60a1138d775d0f03eec14ee0", label: "COhatch Kenwood" },
	{ value: "606b0d3422b1003de911eea7", label: "COhatch Dublin" },
	{
		value: "6287bd60e608c53fd3fae8d3",
		label: "COhatch Waterfront",
		city: "Homestead",
		state: "Pennsylvania",
	},
];

export const BADGES = [
	{
		badgeName: "Boost Scholar",
		badge:
			"https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/cohatch_boost_scholar.png",
	},
	{
		badgeName: "Give Scholar",
		badge:
			"https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/cohatch_impact_giver.png",
	},
	{
		badgeName: "Impact Scholar",
		badge:
			"https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/cohatch_impact_sponsor.png",
	},
	{
		badgeName: "COhatch Member",
		badge:
			"https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/cohatch_member.png",
	},
	{
		badgeName: "Worthington Farmers Market",
		badge:
			"https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/cohatch_market.png",
	},
];

//Template for
export const COUPON_BG_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/coupons/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const LOGO_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/10.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/10.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/11.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/11.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
	{
		small: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/12.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/12.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/13.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/13.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/14.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/14.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/15.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/15.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/16.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/logo/16.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const LISTING_COVER_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/listing_cover_photo/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const GROUP_TEMPLATE_BANNER = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_banner/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const GROUP_LOGO_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/10.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/10.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/11.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/11.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
	{
		small: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/12.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/12.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/13.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/13.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/14.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/14.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/15.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/15.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/16.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/group_logo/16.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const PROFILE_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/10.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 10",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/10.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/11.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 11",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/11.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
	{
		small: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/12.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 12",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/12.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/13.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 13",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/13.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/14.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 14",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/14.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/15.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 15",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/15.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/16.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 16",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile/16.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const PROFILE_COVER_TEMPLATE = [
	{
		small: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/1.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 1",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/1.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/2.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 2",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/2.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/3.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 3",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/3.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/4.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 4",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/4.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/5.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 5",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/5.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/6.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 6",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/6.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/7.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 7",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/7.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/8.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 8",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/8.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},

	{
		small: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/9.png",
			width: 250,
			height: 250,
		},
		large: {
			filename: "template 9",
			url: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/templates/profile_cover_photo/9.png",
			width: 250,
			height: 250,
		},
		type: "image",
	},
];

export const PROPERTY_CATEGORY = [
	{ value: "beach_vacation", label: "Beach Vacation" },
	{ value: "cabins", label: "Cabins" },
	{ value: "camper", label: "Campers" },
	{ value: "disney_resort", label: "Disney" },
	{ value: "family_homes", label: "For Large Groups" },
	{ value: "tiny_home", label: "Tiny Homes" },
	{ value: "unique_stay", label: "Unique Stay" },
	{ value: "local_stays", label: "Local Stays" },
];

export const PROPERTY_DISCOUNT = [
	{ value: "5", label: "5%" },
	{ value: "10", label: "10%" },
	{ value: "15", label: "15%" },
	{ value: "20", label: "20%" },
	{ value: "30", label: "30%" },
];

export const PROPERTY_BATHROOM_AMENITIES = [
	{
		value: "bathroom-essentials",
		label: "Essentials (towels, soap, toilet paper)",
		icon: GiTowel,
	},
	{ value: "hair-dryer", label: "Hair Dryer", icon: GiComb },
	{ value: "shampoo", label: "Shampoo", icon: TbBottle },
	{ value: "conditioner", label: "Conditioner", icon: TbBottle },
	{ value: "body-wash", label: "Body Wash", icon: TbBottle },
	{ value: "body-lotion", label: "Body Lotion", icon: TbBottle },
	{ value: "bathtub", label: "Bathtub", icon: FaBath },
	{ value: "shower", label: "Shower", icon: FaShower },
];

export const PROPERTY_BEDROOM_AMENITIES = [
	{ value: "bed-linens", label: "Bed Linens", icon: BiBlanket },
	{
		value: "blankets-pillows",
		label: "Extra Blankets + Pillows",
		icon: GiPillow,
	},
	{
		value: "clothing-storage",
		label: "Clothing Storage (Dresser, Closet)",
		icon: BiCloset,
	},
	{ value: "iron", label: "Iron", icon: MdIron },
	{ value: "ironing-board", label: "Ironing Board", icon: MdIron },
	{ value: "hangers", label: "Hangers", icon: TbHanger },
	{ value: "washer", label: "Washer", icon: GiWashingMachine },
	{ value: "dryer", label: "Dryer", icon: MdLocalLaundryService },
	{
		value: "laundry-detergent",
		label: "Laundry Detergent",
		icon: GiSpiralBottle,
	},
];

export const PROPERTY_ENTERTAINMENT_AMENITIES = [
	{ value: "tv", label: "TV", icon: FaTv },
	{ value: "wifi", label: "Wifi", icon: FaWifi },
	{ value: "cable", label: "Cable", icon: GiCableStayedBridge },
	{ value: "streaming-services", label: "Streaming Services", icon: TbApps },
	{ value: "ping-pong", label: "Ping Pong Table", icon: GiPingPongBat },
	{ value: "pool-table", label: "Pool Table", icon: GiPoolTableCorner },
	{ value: "game-console", label: "Game Console", icon: GiConsoleController },
	{ value: "bluetooth-speaker", label: "Bluetooth Speaker", icon: MdSpeaker },
	{ value: "dedicated-workspace", label: "Dedicated Workspace", icon: GiDesk },
];

export const PROPERTY_KITCHEN_AMENITIES = [
	{
		value: "refrigerator",
		label: "Refrigerator",
		icon: CgSmartHomeRefrigerator,
	},
	{ value: "microwave", label: "Microwave", icon: MdMicrowave },
	{
		value: "silverware",
		label: "Dishes & Silverware (Bowls, Plates, Cups, Forks, Etc.)",
		icon: FaUtensils,
	},
	{ value: "dishwasher", label: "Dishwasher", icon: CgSmartHomeWashMachine },
	{ value: "stove", label: "Stove", icon: GiGasStove },
	{ value: "oven", label: "Oven", icon: MdOutlineMicrowave },
	{ value: "coffee-maker", label: "Coffee Maker", icon: MdOutlineCoffeeMaker },
	{ value: "toaster", label: "Toaster", icon: GiToaster },
	{ value: "dining-table", label: "Dining Table", icon: MdDining },
	{
		value: "cooking-pots",
		label: "Cooking Basics (pots & pans, oil, salt & pepper)",
		icon: GiCookingPot,
	},
	{ value: "tea-kettle", label: "Tea Kettle", icon: GiTeapotLeaves },
];

export const PROPERTY_SAFETY_AMENITIES = [
	{ value: "smoke-alarm", label: "Smoke Alarm", icon: RiAlarmWarningFill },
	{
		value: "carbon-monoxide-alarm",
		label: "Carbon Monoxide Alarm",
		icon: RiAlarmWarningFill,
	},
	{
		value: "fire-extinguisher",
		label: "Fire Extinguisher",
		icon: FaFireExtinguisher,
	},
	{ value: "first-aid-kit", label: "First Aid Kit", icon: GiFirstAidKit },
	{ value: "security-cameras", label: "Security Cameras", icon: GiCctvCamera },
	{ value: "gate", label: "Gate", icon: GiGate },
];

export const PROPERTY_COOLING_AMENITIES = [
	{ value: "ac", label: "Central Air Conditioning", icon: GiThermometerCold },
	{ value: "heater", label: "Central Heating", icon: GiThermometerHot },
	{ value: "indoor-fireplace", label: "Indoor Fireplace", icon: GiFireplace },
	{ value: "ceiling-fan", label: "Ceiling Fan", icon: FaFan },
];

export const PROPERTY_FAMILY_AMENITIES = [
	{
		value: "childrens-books-toys",
		label: "Children's Books & Toys",
		icon: TbBooks,
	},
	{ value: "crib", label: "Crib", MdCrib },
	{ value: "high-chair", label: "High Chair", icon: GiWoodenChair },
	{ value: "board-games", label: "Board Games", icon: FaBox },
];

export const PROPERTY_PARKING_AMENITIES = [
	{
		value: "premise-parking",
		label: "Free Parking on Premises",
		icon: FaParking,
	},
	{ value: "garage-parking", label: "Garage Parking", icon: GiHomeGarage },
	{
		value: "free-street-parking",
		label: "Free Street Parking",
		icon: FaParking,
	},
	{
		value: "paid-street-parking",
		label: "Paid Street Parking",
		icon: FaParking,
	},
	{ value: "ev-charger", label: "EV Charger", icon: MdElectricCar },
	{ value: "bus-van-parking", label: "Bus / Van Parking", icon: FaParking },
];

export const PROPERTY_OUTDOOR_AMENITIES = [
	{ value: "private-backyard", label: "Private Backyard", icon: GiWoodenDoor },
	{ value: "fenced-yard", label: "Fenced Yard", icon: MdFence },
	{ value: "private-entrance", label: "Private Entrance", icon: GiDoorway },
	{ value: "fire-pit", label: "Fire Pit", icon: IoMdBonfire },
	{ value: "outdoor-furniture", label: "Outdoor Furniture", icon: GiSofa },
	{ value: "bbq-grill", label: "BBQ Grill", icon: MdOutdoorGrill },
	{ value: "gas-grill", label: "Gas Grill", icon: MdOutdoorGrill },
	{ value: "beach-towels", label: "Beach Towels", icon: GiTowel },
	{ value: "beach-umbrellas", label: "Beach Umbrellas", icon: FaUmbrellaBeach },
	{ value: "beach-chairs", label: "Beach Chairs", icons: GrLounge },
	{ value: "private-pool", label: "Private Pool", icon: FaSwimmingPool },
	{ value: "shared-pool", label: "Shared Pool", icon: FaSwimmingPool },
	{ value: "private-hot-tub", label: "Private Hot Tub", icon: FaHotTub },
	{ value: "shared-hot-tub", label: "Shared Hot Tub", icon: FaHotTub },
	{ value: "beach-access", label: "Beach Access", icon: MdBeachAccess },
	{ value: "waterfront", label: "Waterfront", icon: FaWater },
	{ value: "patio", label: "Patio or Balcony", icon: MdBalcony },
	{ value: "mountain-view", label: "Mountain View", icon: GiMountains },
	{ value: "beach-view", label: "Beach View", icon: TbBeach },
	{ value: "lake-view", label: "Lake View", icon: MdWater },
	{ value: "outdoor-shower", label: "Outdoor Shower", icon: GiShower },
];

export const PROPERTY_ACCESSIBILITY_AMENITIES = [
	{ value: "elevator", label: "Elevator", icon: MdElevator },
	{
		value: "wheelchair-accessible",
		label: "Wheelchair Accessible",
		icon: FaWheelchair,
	},
	{
		value: "accessible-parking",
		label: "Accessible Parking Available",
		icon: FaWheelchair,
	},
];

export const PROPERTY_INFO_FEATURES = [
	{ value: "eventsAllowed", label: "Events Allowed", icon: GiPartyPopper },
	{ value: "petFriendly", label: "Pet Friendly", icon: MdPets },
	{ value: "childrenWelcome", label: "Children Welcome", icon: FaChild },
];

export const DISNEY_CLUBS = [
	{
		_id: 1,
		minPrice: "+1000",
		propertyName: "Aulani, Disney Vacation Club Villas",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 21.33956,
			lng: -158.12328,
		},
	},
	{
		_id: 2,
		minPrice: "+1000",
		propertyName: "Bay Lake Tower at Disney’s Contemporary",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.41641,
			lng: -81.57363,
		},
	},
	{
		_id: 3,
		minPrice: "+1000",
		propertyName: "Boulder Ridge Villas at Disney Wilderness Lodge",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.409169233902276,
			lng: -81.57296260692505,
		},
	},
	{
		_id: 4,
		minPrice: "+1000",
		propertyName: "Copper Creek Villas & Cabins at Disney’s Wilderness Lodge",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.409169233902276,
			lng: -81.57296260692505,
		},
	},
	{
		_id: 5,
		minPrice: "+1000",
		propertyName: "Disney’s Animal Kingdom Villas - Jambo House",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.358598267188572,
			lng: -81.58711550229158,
		},
	},
	{
		_id: 6,
		minPrice: "+1000",
		propertyName: "Disney’s Animal Kingdom Villas - Kidani",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.354339716071504,
			lng: -81.60547700237237,
		},
	},
	{
		_id: 7,
		minPrice: "+1000",
		propertyName: "Disneys’s Beach Club Villas",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.372025924711167,
			lng: -81.55503006004486,
		},
	},
	{
		_id: 8,
		minPrice: "+1000",
		propertyName: "Disney’s BoardWalk Villas",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.368959986247027,
			lng: -81.55277684655422,
		},
	},
	{
		_id: 9,
		minPrice: "+1000",
		propertyName: "Disney’s Hilton Head Island Resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 32.182932688740564,
			lng: -80.72779394646074,
		},
	},
	{
		_id: 10,
		minPrice: "+1000",
		propertyName: "Disney’s Old Key West Resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.374376339576465,
			lng: -81.53348773120834,
		},
	},
	{
		_id: 11,
		minPrice: "+1000",
		propertyName: "Disney’s Polynesian Villas & Bunglows",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.407047604386854,
			lng: -81.5825306735347,
		},
	},
	{
		_id: 12,
		minPrice: "+1000",
		propertyName: "Disney’s Riviera Resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.366136866880257,
			lng: -81.54157869174757,
		},
	},
	{
		_id: 13,
		minPrice: "+1000",
		propertyName: "Disney’s Saratoga Springs Resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.376492826838103,
			lng: -81.52386221957258,
		},
	},
	{
		_id: 14,
		minPrice: "+1000",
		propertyName: "Disney’s Vero Beach Resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 27.76142372666093,
			lng: -80.396914859806,
		},
	},
	{
		_id: 15,
		minPrice: "+1000",
		propertyName: "The villas at Disney’s Grand Californian Hotel",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 33.80788834495861,
			lng: -117.92220417339915,
		},
	},
	{
		_id: 16,
		minPrice: "+1000",
		propertyName: "The Villas at Disney’s Grand Floridian resort",
		propertyLocation: {
			streetAddress: "",
			city: "",
			state: "",
			zip: "",
			lat: 28.411448924189642,
			lng: -81.59030300237107,
		},
	},
];

export const EVENT_SPACES = [
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c05286a1de439",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 18,
		name: "Mason Theatre",
		office: {
			physicalAddress: {
				city: "Mason",
				state: "Ohio",
			},
			_id: "5d1bcda0dbd6e40010479eec",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2022-09-12T17:17:45.192Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "601982b6419420042d33bf7d",
		room: "606b05d5f7ff2fe080124f3e",
		target: "606eda71a7656e67d13b75de",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/theatre-1624283247618.jpeg",
		color: "#8400ff",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c055d6a1de438",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 27,
		name: "Foyer + Patio",
		office: {
			physicalAddress: {
				city: "Mason",
				state: "Ohio",
			},
			_id: "5d1bcda0dbd6e40010479eec",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2022-09-08T14:43:45.015Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		room: "606b05d5f7ff2fe080124f3e",
		target: "60791c0ef139aa02dbb1aa1a",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/foyer-1624283177071.jpeg",
		color: "#ff00fb",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c0584e21de437",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 40,
		name: "Atrium",
		office: {
			physicalAddress: {
				city: "Mason",
				state: "Ohio",
			},
			_id: "5d1bcda0dbd6e40010479eec",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2022-09-08T14:43:38.701Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		room: "606b05d5f7ff2fe080124f3e",
		target: "60791c2b0f111d5df9654610",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/atrium-1624283210936.jpeg",
		color: "#00ffea",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "615257f36e01d3529809935e",
			price: 175,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-07-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60f98317052eefecedf85f46",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Mason",
				state: "Ohio",
			},
			_id: "5d1bcda0dbd6e40010479eec",
		},
		name: "Reading Room & Behind the Bookshelf",
		number: 45,
		size: 28,
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/reading-room-behind-the-bookshelf-1646085234532.jpeg",
		color: "#ff8c00",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-07-22T14:39:19.226Z",
		modifiedAt: "2022-10-19T14:32:34.766Z",
		createdBy: "603416051b913b9921990a72",
		modifiedBy: "6107fe7e39b7314650a133b5",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "6087204631fef29a3f363814",
			price: 200,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-06-03T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60b9788159c5e89bbd339db7",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cleveland",
				state: "Ohio",
			},
			_id: "5fd23f130ecc6d8a668543db",
		},
		room: "606b04f122b10059b1111c6f",
		name: "Moon Room",
		number: 26,
		parent: null,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-06-04T00:49:05.070Z",
		modifiedAt: "2022-07-27T20:48:02.648Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		target: "607d825b0bb06440f84ebff5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/moon-room-1625591687247.jpeg",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-07-06T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60e4c9578dfb48ba1f3e7a28",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cleveland",
				state: "Ohio",
			},
			_id: "5fd23f130ecc6d8a668543db",
		},
		room: "606b04f122b10059b1111c6f",
		name: "Front Lounge",
		number: 43,
		size: 60,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-07-06T21:21:27.156Z",
		modifiedAt: "2022-03-16T19:14:54.240Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60819030561c05475e1de42b",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 40,
		name: "Inside Outside",
		office: {
			physicalAddress: {
				city: "Noblesville",
				state: "Indiana",
			},
			_id: "606af848e1fae5fe0aa1c2af",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2021-07-19T20:06:31.193Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "60be47575b1b595339fa52c7",
		room: "606b056033fc9ab2e452e122",
		target: "607d82b30bb06403554ecf45",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/inside-outside-1623865346204.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60819030561c0509fd1de43a",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 14,
		name: "Launch Pad",
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "606af857c10359a5c9f3f6ed",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2021-07-27T15:56:44.866Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "603416051b913b9921990a72",
		room: "60791667cfce7c4ca09f92ca",
		color: "#f06dfa",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/launch-pad-1627401401518.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60f81fae9469275613c18d02",
			price: 225,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-07-01T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60f81e041e9ec96ef6116450",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "606af857c10359a5c9f3f6ed",
		},
		name: "North High Brewing",
		number: 44,
		size: 35,
		color: "#ebbc00",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-07-21T13:15:48.957Z",
		modifiedAt: "2021-07-27T15:56:34.379Z",
		createdBy: "603416051b913b9921990a72",
		modifiedBy: "603416051b913b9921990a72",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/north-high-brewing-1627401386869.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60871f91cb498d3789024d5d",
			price: 125,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60819030561c0538c71de422",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 50,
		name: "The Exchange",
		office: {
			physicalAddress: {
				city: "Springfield",
				state: "Ohio",
			},
			_id: "606af872e1fae54e2da1c315",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2021-07-09T18:11:19.688Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "60cccbe38d8af400917ecc55",
		room: "606d8a4af2a3a572b5f3a699",
		target: "6080774ce2aa65402e44e5bf",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c0505281de3f5",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 50,
		name: "The Lookout",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af8810b1f05378ad799e7",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2023-02-07T20:11:45.351Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "5fc6bed0b2186818d1d008b5",
		room: "607691d34fe9a2a936eca4fa",
		target: "607d7eec62a3423bd27ad7b5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-lookout-1624641713423.jpeg",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "615257f36e01d3529809935e",
			price: 175,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-06-08T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60bfb65d954a254f5e88a68f",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Delaware",
				state: "Ohio",
			},
			_id: "606af88d5e43340c60c209b8",
		},
		room: "606d8a01f2a3a53c86f3a236",
		name: "Hall of Mirrors (Patio Included)",
		number: 32,
		parent: null,
		size: 30,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-06-08T18:26:37.241Z",
		modifiedAt: "2023-01-10T18:16:52.586Z",
		createdBy: "5fc6bed0b2186818d1d008b5",
		modifiedBy: "60c8bb2234b219f4b1664552",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "62bb5aca61cfcce914c63f13",
			price: 100,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-06-08T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60bfb6be5048934ca2c67700",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Delaware",
				state: "Ohio",
			},
			_id: "606af88d5e43340c60c209b8",
		},
		room: "607006dc9ad6921b4a11fb66",
		name: "Delaware Theater",
		number: 33,
		parent: null,
		description:
			"Host a movie night in our 30 person theater room! Complete with reclining chairs, box seating, and popcorn machine. \n\nAlso great for presentations!",
		size: 30,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-06-08T18:28:14.351Z",
		modifiedAt: "2023-03-08T15:38:38.685Z",
		createdBy: "5fc6bed0b2186818d1d008b5",
		image:
			"https://www.cohatch.com/wp-content/uploads/2022/03/Delaware_Theater-1_web-800x534.jpg",
		modifiedBy: "5fc9856c8128422c388a1e54",
		amenities: [],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60f81fae9469275613c18d02",
			price: 225,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-06-08T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60bfb73e954a25aac288d394",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af89d5e433411eec209cf",
		},
		room: "606b0bd74209ca26d28b743e",
		name: "The Gathering Place",
		number: 34,
		parent: null,
		size: 40,
		description: "",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-06-08T18:30:22.348Z",
		modifiedAt: "2022-12-12T14:38:07.379Z",
		createdBy: "5fc6bed0b2186818d1d008b5",
		modifiedBy: "5fc6bed0b2186818d1d008b5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-gathering-place-1624641636646.jpeg",
		amenities: [
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: false,
			teams: ["5fc68cd1f8497cb16e46d92f"],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-01-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63c6cd26fee49456e05dff05",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af89d5e433411eec209cf",
		},
		room: "606b0bd74209ca26d28b743e",
		name: "The Rock Wall",
		number: 118,
		parent: null,
		size: 4,
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-rock-wall-1673973018338.jpeg",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-17T16:30:30.305Z",
		modifiedAt: "2023-01-17T16:30:30.305Z",
		createdBy: "5fc9856c8128422c388a1e54",
		modifiedBy: "5fc9856c8128422c388a1e54",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60f81fae9469275613c18d02",
			price: 225,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c0564e61de401",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 200,
		name: "Polaris East, The Pub",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af8ab5e4334f28dc209e7",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2023-03-13T14:36:31.576Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "5fc6bed0b2186818d1d008b5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/whole-venue-1620849130806.jpeg",
		amenities: [
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c38a146d940",
		],
		room: "606b04a722b10069ea111bbe",
		target: null,
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-08-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "626ff11725342b093c82629f",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af8ab5e4334f28dc209e7",
		},
		room: "61f00480e860123beb2b499a",
		name: "The Meridian",
		target: "61f0eb66a1b4fe782e1ff38e",
		number: 70,
		size: 120,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-05-02T14:56:23.471Z",
		modifiedAt: "2022-08-15T15:13:50.034Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		description: "Corporate or Social Event at Polaris Meridian",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-meridian-1660576425404.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: false,
			teams: ["5fc68cd1f8497cb16e46d92f"],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-01-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63c6cdb8892804513e11b4e3",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606af8ab5e4334f28dc209e7",
		},
		room: "606b04a722b10069ea111bbe",
		name: "Mobi",
		number: 119,
		parent: null,
		size: 4,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-17T16:32:56.349Z",
		modifiedAt: "2023-01-31T16:23:31.487Z",
		createdBy: "5fc9856c8128422c388a1e54",
		modifiedBy: "5fc9856c8128422c388a1e54",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/mobi-1675182202525.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "640f3567b1e08986b7964940",
			price: 175,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "60819030561c05bb411de3ea",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 35,
		name: "Kilbourne Room (+Patio+Kitchen+Game Room)",
		office: {
			physicalAddress: {
				city: "Worthington",
				state: "Ohio",
			},
			_id: "606af8be5e43345791c20a01",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2023-03-13T14:39:08.342Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "5fc6bed0b2186818d1d008b5",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
		],
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/kilbourne-room-patiokitchengame-room-1678289567331.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60871f91cb498d3789024d5d",
			price: 125,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-04-01T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60819030561c056d9f1de3e9",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		size: 50,
		name: "Community Hall & Kitchen",
		office: {
			physicalAddress: {
				city: "Worthington",
				state: "Ohio",
			},
			_id: "606af8e161a5fbfe224c3002",
		},
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-04-22T15:03:12.873Z",
		modifiedAt: "2021-06-25T17:15:23.290Z",
		createdBy: "5c175db8840a03001044e5f9",
		modifiedBy: "601982b6419420042d33bf7d",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/community-hall-kitchen-1624641286620.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: false,
			teams: ["5fc68cd1f8497cb16e46d92f"],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-01-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63c6cc1ec384c50dbcaacdbc",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Worthington",
				state: "Ohio",
			},
			_id: "606af8e161a5fbfe224c3002",
		},
		name: "Bounce House",
		number: 117,
		size: 4,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-17T16:26:06.965Z",
		modifiedAt: "2023-01-17T16:27:04.106Z",
		createdBy: "5fc9856c8128422c388a1e54",
		modifiedBy: "5fc9856c8128422c388a1e54",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/bounce-house-1673972788751.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-05-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "609d76f1df25cd1d5c37f9a0",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f3a22b10064331231b7",
		name: "Mulligan Room",
		number: 9,
		parent: null,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-05-13T18:58:57.819Z",
		modifiedAt: "2022-01-07T18:46:58.740Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		target: "606d7632e6ea5c3eb3a6ccaa",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/mulligan-room-1641581178901.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "61525899bc84fa115260b8ef",
			price: 150,
		},
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-05-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "609d78d9df25cd2a133820ca",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f4222b10087b91231dc",
		name: "Stained Glass Room",
		number: 17,
		size: 18,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-05-13T19:07:05.296Z",
		modifiedAt: "2023-02-21T21:12:37.107Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5d13a6f5f387e501961b3e28",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/stained-glass-room-1623264953582.jpeg",
		target: "606ea1f00a6d60c63cfd68a7",
		amenities: [
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720abcb498d976e025921",
			price: 250,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-05-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "609d7974df25cd255c383075",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f4222b10087b91231dc",
		name: "Big Room",
		number: 20,
		parent: null,
		size: 40,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-05-13T19:09:40.093Z",
		modifiedAt: "2022-01-07T18:45:56.858Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		target: "606ea2ca42c06d76b345cbdb",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/big-room-1641581119466.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60871f91cb498d3789024d5d",
			price: 125,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-04-05T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "624c45c1f26efe319e34aeae",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f4222b10087b91231dc",
		name: "Patio and Main Kitchen Area",
		target: "607d86f062a34242f97c62f3",
		number: 21,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-04-05T13:36:01.100Z",
		modifiedAt: "2022-04-05T13:36:01.100Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "6087280631fef235f23688c2",
			price: 100,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-04-20T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "626000cec575ca0d39eb745c",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f3a22b10064331231b7",
		name: "Downstairs Kitchen Space",
		number: 84,
		size: 30,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-04-20T12:47:10.585Z",
		modifiedAt: "2022-04-20T12:47:52.095Z",
		createdBy: "60a2d7c5396a4c08290731e1",
		modifiedBy: "60a2d7c5396a4c08290731e1",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-21T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63f52c7e472bb20007283d46",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f4222b10087b91231dc",
		name: "2-24",
		target: "606ea1f00a6d60c63cfd68a7",
		number: 24,
		size: 15,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-02-21T20:41:34.188Z",
		modifiedAt: "2023-02-21T20:41:34.188Z",
		createdBy: "6107fe70f92a67738ed8db1a",
		modifiedBy: "6107fe70f92a67738ed8db1a",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-21T00:00:00.000Z",
				endDate: "2023-03-01T00:00:00.000Z",
			},
		],
		_id: "63f52ca2472bb20007283f1e",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "606af961262dad011aa7c154",
		},
		room: "606b0f4222b10087b91231dc",
		name: "Stained Glass",
		target: "606ea1f00a6d60c63cfd68a7",
		number: 25,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-02-21T20:42:10.498Z",
		modifiedAt: "2023-03-01T21:46:41.237Z",
		createdBy: "6107fe70f92a67738ed8db1a",
		modifiedBy: "6107fe70f92a67738ed8db1a",
		size: 16,
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720abcb498d976e025921",
			price: 250,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-10-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "6140b6741da7a73b86096bb9",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Dublin",
				state: "Ohio",
			},
			_id: "606b0d3422b1003de911eea7",
		},
		room: "606b0d49c422db31dbeeae26",
		name: "Black and Tan Room",
		target: "6140b622b9dad64aeb373812",
		number: 4,
		size: 25,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-09-14T14:49:24.699Z",
		modifiedAt: "2022-09-28T14:19:50.473Z",
		createdBy: "601982b6419420042d33bf7d",
		modifiedBy: "601982b6419420042d33bf7d",
		color: "#f59ff5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/black-and-tan-room-1633373482165.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-03-02T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "621f98d9d88b5bd56e56bdfc",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Dublin",
				state: "Ohio",
			},
			_id: "606b0d3422b1003de911eea7",
		},
		room: "606b0d41c422dbde53eeae1f",
		name: "Commons",
		number: 72,
		size: 50,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-03-02T16:18:33.770Z",
		modifiedAt: "2023-03-02T15:02:31.754Z",
		createdBy: "5fc6bed0b2186818d1d008b5",
		modifiedBy: "60e468c5e8edfef7e4ae5722",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-11-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "619522bfff324323968ba5aa",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Milford",
				state: "Ohio",
			},
			_id: "606b0ea033fc9a7d5b53a1b7",
		},
		room: "606b0eacc422db62a6eefb3b",
		name: "Barracuda Room",
		number: 56,
		size: 12,
		color: "#0037ff",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-11-17T15:41:51.009Z",
		modifiedAt: "2022-09-29T20:34:05.364Z",
		createdBy: "603416051b913b9921990a72",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/barracuda-room-1664483641864.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60871f91cb498d3789024d5d",
			price: 125,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-11-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "6195230e00f451a3700a51db",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Milford",
				state: "Ohio",
			},
			_id: "606b0ea033fc9a7d5b53a1b7",
		},
		room: "606b0eb5c422db861beefb4d",
		name: "Deck",
		number: 57,
		size: 20,
		color: "#ff9500",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-11-17T15:43:10.453Z",
		modifiedAt: "2021-11-23T16:29:33.323Z",
		createdBy: "603416051b913b9921990a72",
		modifiedBy: "60901d312f19e2a95f25ffa3",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/deck-1637684968017.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: false,
			teams: ["5fc68cd1f8497cb16e46d92f"],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-01-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63c6ce06fee494e3525e1117",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Milford",
				state: "Ohio",
			},
			_id: "606b0ea033fc9a7d5b53a1b7",
		},
		room: "606b0eacc422db62a6eefb3b",
		name: "Gracie",
		number: 120,
		parent: null,
		size: 2,
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/gracie-1673973242496.jpeg",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-17T16:34:14.207Z",
		modifiedAt: "2023-01-17T16:34:14.207Z",
		createdBy: "5fc9856c8128422c388a1e54",
		modifiedBy: "5fc9856c8128422c388a1e54",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "62bb5aca61cfcce914c63f13",
			price: 100,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-02-28T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62100e6ee4fe9638a3125727",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [],
		office: {
			physicalAddress: {
				city: "Beachwood",
				state: "Ohio",
			},
			_id: "606b10f2f7ff2fc987137673",
		},
		room: "606b10faf7ff2f8a34137693",
		name: "The Watkin's Green",
		number: 70,
		size: 8,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-02-18T21:23:58.558Z",
		modifiedAt: "2022-06-28T19:48:18.297Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		target: "6213ae2ed9693301ce90727d",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-watkins-green-1648736333632.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-02-18T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62100e8be4fe962feb125798",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Beachwood",
				state: "Ohio",
			},
			_id: "606b10f2f7ff2fc987137673",
		},
		room: "606b10faf7ff2f8a34137693",
		name: "The Hive",
		number: 71,
		size: 75,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-02-18T21:24:27.561Z",
		modifiedAt: "2022-06-28T17:26:52.097Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		target: "6213adbd5732ff355bbceebe",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-hive-1648736308046.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-03-11T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "622bb39b3dacaadf6b351bee",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Beachwood",
				state: "Ohio",
			},
			_id: "606b10f2f7ff2fc987137673",
		},
		room: "606b110233fc9a157353f5cd",
		name: "Open Space Cost",
		number: 73,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-03-11T20:39:55.282Z",
		modifiedAt: "2022-03-11T20:39:55.282Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-03-11T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "622bb40df50ae071979f17fd",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Beachwood",
				state: "Ohio",
			},
			_id: "606b10f2f7ff2fc987137673",
		},
		room: "606b110233fc9a157353f5cd",
		name: "Total Floor Buyout",
		number: 74,
		size: 80,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-03-11T20:41:49.660Z",
		modifiedAt: "2022-03-11T20:41:49.660Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60bfb8402bf48b86ea42d201",
			price: 165,
		},
		access: {
			full: false,
			public: false,
			teams: ["60d22c35a0d854e8795e6c23"],
			plans: [],
		},
		availability: [
			{
				startDate: "2021-06-08T00:00:00.000Z",
				endDate: null,
			},
		],
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c38a146d940",
		],
		_id: "60bfb8c8954a25f22589073c",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Columbus",
				state: "Ohio",
			},
			_id: "606b14899ff4771341cc9201",
		},
		name: "Design Lab",
		number: 35,
		parent: null,
		size: 24,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2021-06-08T18:36:56.373Z",
		modifiedAt: "2021-08-11T19:16:28.325Z",
		createdBy: "5fc6bed0b2186818d1d008b5",
		modifiedBy: "60c8bb2234b219f4b1664552",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/design-lab-1624641400026.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "615257f36e01d3529809935e",
			price: 175,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-05-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62797c37805a3a3161065e8a",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "60a1138d775d0f03eec14ee0",
		},
		room: "60a113c5b9cb150ca9b593c2",
		name: "Plaza 98",
		target: "627bbe7102364228b440a282",
		number: 34,
		size: 25,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-05-09T20:40:23.146Z",
		modifiedAt: "2022-09-29T18:19:32.954Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/plaza-98-1664475568178.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "62fbfc77fb676d6a0d1977c6",
			price: 50,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-05-11T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "627bc36857d1cf4a96be6d55",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "60a1138d775d0f03eec14ee0",
		},
		room: "60a113c5b9cb150ca9b593c2",
		name: "Stardust Sports Club",
		target: "627aca18e9ea11745e769f2c",
		number: 35,
		size: 10,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-05-11T14:08:40.781Z",
		modifiedAt: "2023-02-27T20:44:19.094Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "6107fe7e39b7314650a133b5",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/stardust-sports-club-1657565175208.jpeg",
		description: "",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60871f91cb498d3789024d5d",
			price: 125,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-10-24T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "6356d858d8a5bbc90ae593df",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "60a1138d775d0f03eec14ee0",
		},
		room: "60a113c5b9cb150ca9b593c2",
		name: "Kitchenette Space",
		target: "6356d7e7bcf2dea8d460e23a",
		number: 37,
		size: 20,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-10-24T18:24:24.745Z",
		modifiedAt: "2022-11-15T16:24:56.124Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "6107fe7e39b7314650a133b5",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-03-17T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62337946f09d16273789123e",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Zionsville",
				state: "Indiana",
			},
			_id: "61097250b12e1486413b171a",
		},
		room: "612485698a76f67e1672339a",
		name: "Gravity Room",
		target: "6233791bf09d16bf1889117f",
		number: 2,
		size: 50,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-03-17T18:09:10.372Z",
		modifiedAt: "2022-08-25T15:17:05.827Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/gravity-room-1661440621444.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "62f124bba0a8f70dda00a6f2",
			price: 125,
		},
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-07-06T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62c5ef7422fbad04d81e9f64",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
			"5fc68cd1f8497c29ef46d93f",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "6109728eb12e145cca3b1f59",
		},
		room: "61771e0bafed7223e8db5913",
		name: "The Crown Jewel",
		target: "617736827f36640724e788a3",
		number: 4,
		size: 25,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-07-06T20:24:20.589Z",
		modifiedAt: "2022-08-23T16:53:02.755Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-crown-jewel-1661273579745.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-08-08T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "62f123941fec28ccf97c94d6",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Indianapolis",
				state: "Indiana",
			},
			_id: "6109728eb12e145cca3b1f59",
		},
		room: "61771e0bafed7223e8db5913",
		name: "The Pint Room",
		target: "62f1234a1fec2827e87c903d",
		number: 7,
		size: 20,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-08-08T14:54:12.741Z",
		modifiedAt: "2022-08-23T16:53:14.906Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-pint-room-1661273591501.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-01-07T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "61d89c1b04cfa1b43661d7d0",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "St. Petersburg",
				state: "Florida",
			},
			_id: "61435e994274f6b1e4d2bff8",
		},
		room: "6153a18eb0b430c730a7287a",
		name: "harlan room",
		target: "6153b47992189d7816919ea6",
		number: 1,
		size: 21,
		area: 38183146.99627855,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-01-07T20:01:31.263Z",
		modifiedAt: "2023-03-14T20:19:19.650Z",
		createdBy: "613fa2c717c2dde7421653b9",
		modifiedBy: "6140b068ca0270741e76d7b3",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-01-07T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "61d89cbbd80f2245fe29d4ee",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "St. Petersburg",
				state: "Florida",
			},
			_id: "61435e994274f6b1e4d2bff8",
		},
		room: "6154a90ccdfaff12dc7f70e6",
		name: "50/50 Room",
		target: "6154c039eb7d377175d2cde0",
		number: 1,
		size: 25,
		area: 47937965.57197015,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-01-07T20:04:11.044Z",
		modifiedAt: "2022-01-07T20:04:11.044Z",
		createdBy: "613fa2c717c2dde7421653b9",
		modifiedBy: "613fa2c717c2dde7421653b9",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-01-07T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "61d89d2f2a89a74c146173a6",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "St. Petersburg",
				state: "Florida",
			},
			_id: "61435e994274f6b1e4d2bff8",
		},
		room: "6154a90ccdfaff12dc7f70e6",
		name: "Launchpad",
		target: "6154c022eb7d378123d2cd2f",
		number: 2,
		parent: null,
		size: 15,
		area: 40784431.94979631,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-01-07T20:06:07.558Z",
		modifiedAt: "2023-03-14T20:21:12.067Z",
		createdBy: "613fa2c717c2dde7421653b9",
		modifiedBy: "6140b068ca0270741e76d7b3",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720abcb498d976e025921",
			price: 250,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-07-10T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "628cda7d3171e0cce640b64e",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Lakeland",
				state: "Florida",
			},
			_id: "61435fba63579c94b09276e5",
		},
		room: "61ddda9c662a6cfac9db779f",
		name: "The Everglade",
		number: 92,
		size: 45,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-05-24T13:15:41.604Z",
		modifiedAt: "2023-01-11T18:23:07.634Z",
		createdBy: "613fa2c717c2dde7421653b9",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		target: "628cdcbe3171e0566c40cbed",
		description: "$195/hour",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-everglade-1673461351346.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "608720eb0c4735da8bc978f7",
			price: 300,
		},
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-01-30T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63d8325b05a28a7d896fd588",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Lakeland",
				state: "Florida",
			},
			_id: "61435fba63579c94b09276e5",
		},
		room: "61828873b424c16619d9b169",
		name: "Front Lobby",
		number: 121,
		size: 50,
		description: "Main lobby and arcade room",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/front-lobby-1675113040131.jpeg",
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-30T21:10:51.591Z",
		modifiedAt: "2023-01-30T21:10:51.591Z",
		createdBy: "6140b068ca0270741e76d7b3",
		modifiedBy: "6140b068ca0270741e76d7b3",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "618179b733e3df3768552eb5",
			price: 125,
		},
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-08-22T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "630398a848544341ba65aada",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Carmel",
				state: "Indiana",
			},
			_id: "61a4e2f49b30e7104f11278b",
		},
		name: "Gearbox Room",
		number: 106,
		size: 15,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-08-22T14:54:32.267Z",
		modifiedAt: "2023-02-22T23:07:10.061Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5d13a6f5f387e501961b3e28",
		room: "61a5602938331e7740f411e1",
		target: "61a56addb998312e91e3af6d",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/gearbox-room-1661369861096.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "6087204631fef29a3f363814",
			price: 200,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-08-22T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "630398ec13050e1a093ae225",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Carmel",
				state: "Indiana",
			},
			_id: "61a4e2f49b30e7104f11278b",
		},
		name: "Noir Room",
		number: 108,
		parent: null,
		size: 48,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-08-22T14:55:40.071Z",
		modifiedAt: "2022-08-24T19:38:59.795Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		room: "61a56d66b9df7c89584988b0",
		target: "61a574f1b99831ad37e3e002",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/noir-room-1661369935751.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "62bb5aca61cfcce914c63f13",
			price: 100,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2022-08-22T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "6303998e1a62fa3be75618d2",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [],
		office: {
			physicalAddress: {
				city: "Carmel",
				state: "Indiana",
			},
			_id: "61a4e2f49b30e7104f11278b",
		},
		name: "Kitchen/Reception Area",
		number: 112,
		size: 24,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2022-08-22T14:58:22.069Z",
		modifiedAt: "2022-08-24T19:38:45.376Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/kitchenreception-area-1661369921908.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63ea90ad3f2bc300075933c0",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "61a502e6b36e570a87ac582f",
		},
		room: "63b44e0abeafabc2b0fbcc65",
		name: "Avalon",
		target: "63b4562a7819d3ccf2c1a775",
		number: 1,
		size: 50,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-02-13T19:34:05.211Z",
		modifiedAt: "2023-02-13T19:34:05.211Z",
		createdBy: "6107fe70f92a67738ed8db1a",
		modifiedBy: "6107fe70f92a67738ed8db1a",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63ea9507f8bb6500074ce4a9",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "61a502e6b36e570a87ac582f",
		},
		room: "63b5206abeafab154e044faa",
		name: "Carpe Diem",
		target: "63b529ec4b6a80731f5ecf86",
		number: 16,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-02-13T19:52:39.732Z",
		modifiedAt: "2023-02-13T19:52:39.732Z",
		createdBy: "6107fe70f92a67738ed8db1a",
		modifiedBy: "6107fe70f92a67738ed8db1a",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		access: {
			full: false,
			public: true,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-13T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63ea95233f2bc30007595da4",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		office: {
			physicalAddress: {
				city: "Cincinnati",
				state: "Ohio",
			},
			_id: "61a502e6b36e570a87ac582f",
		},
		room: "63b5206abeafab154e044faa",
		name: "Carpe Diem",
		target: "63b529ec4b6a80731f5ecf86",
		number: 17,
		size: 10,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-02-13T19:53:07.748Z",
		modifiedAt: "2023-02-13T19:53:07.748Z",
		createdBy: "6107fe70f92a67738ed8db1a",
		modifiedBy: "6107fe70f92a67738ed8db1a",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "615257f36e01d3529809935e",
			price: 175,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63d43f5c85c672026cc59075",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497c597b46d93e",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c55da46d93d",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Homestead",
				state: "Pennsylvania",
			},
			_id: "6287bd60e608c53fd3fae8d3",
		},
		room: "628c20a0bcf4cf1322a586cb",
		name: "Smithfield Theater",
		target: "63d43d21c27005677368e0dd",
		number: 5,
		size: 18,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-27T21:17:16.192Z",
		modifiedAt: "2023-02-13T20:54:27.917Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "5fc6bedf759bda74ece01c7d",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/smithfield-theater-1675718425609.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63d43fb704b52a55c3ed2f5b",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Homestead",
				state: "Pennsylvania",
			},
			_id: "6287bd60e608c53fd3fae8d3",
		},
		room: "628c20a0bcf4cf1322a586cb",
		name: "Mill Room",
		target: "63d43dfecff0ca2f00fcc2dc",
		number: 7,
		size: 20,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-27T21:18:47.648Z",
		modifiedAt: "2023-02-06T21:19:43.161Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/mill-room-1675718380349.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
	{
		rate: {
			_id: "60872015060d23144cb6bf04",
			price: 150,
		},
		access: {
			full: false,
			public: false,
			teams: [],
			plans: [],
		},
		availability: [
			{
				startDate: "2023-02-01T00:00:00.000Z",
				endDate: null,
			},
		],
		_id: "63d440c5c27005cbb668f939",
		price: 0,
		deposit: 0,
		parents: [],
		type: "event_space",
		amenities: [
			"5fc68cd1f8497cc30c46d93a",
			"5fc68cd1f8497ceb7746d93b",
			"5fc68cd1f8497c29ef46d93f",
			"5fc68cd1f8497c34ba46d93c",
			"5fc68cd1f8497c38a146d940",
		],
		office: {
			physicalAddress: {
				city: "Homestead",
				state: "Pennsylvania",
			},
			_id: "6287bd60e608c53fd3fae8d3",
		},
		room: "628c20a0bcf4cf1322a586cb",
		name: "Open Coworking Space",
		target: "63d43dbfcf31f626fe9c2337",
		number: 8,
		size: 30,
		organization: "5fc68cd1f8497c50c246d8e9",
		createdAt: "2023-01-27T21:23:17.193Z",
		modifiedAt: "2023-02-06T21:20:05.097Z",
		createdBy: "5fc6bedf759bda74ece01c7d",
		modifiedBy: "603e5eb1c5777bd2207532ec",
		image:
			"//dzrjcxtasfoip.cloudfront.net/user-resources/organization/open-coworking-space-1675718402457.jpeg",
		timezone: "America/New_York",
		status: "event_space",
	},
];

export const WORKSHOP_CATEGORY = [
	{ value: "business_professional", label: "Business and Professional" },
	{ value: "community_culture", label: "Community and Culture" },
	{ value: "drives_donations", label: "Drives + Donations" },
	{ value: "family_education", label: "Family and Education" },
	{ value: "fashion_beauty", label: "Fashion and Beauty" },
	{
		value: "film_media_entertainment",
		label: "Film, Media, and Entertainment",
	},
	{ value: "food_drink", label: "Food and Drink" },
	{ value: "fundraising", label: "Fundraising" },
	{ value: "government_politics", label: "Government and Politics" },
	{ value: "health_wellness", label: "Health and Wellness" },
	{ value: "hobbies", label: "Hobbies and Special Interest" },
	{ value: "home_lifestyle", label: "Home and Lifestyle" },
	{ value: "music", label: "Music" },
	{ value: "performing", label: "Performing and Visual Arts" },
	{ value: "religion_spirituality", label: "Religion and Spirituality" },
	{ value: "school_activities", label: "School Activities" },
	{ value: "science_technology", label: "Science and Technology" },
	{ value: "Seasonal_holidayk", label: "Seasonal and Holiday" },
	{ value: "sports_fitness", label: "Sports and Fitness" },
	{ value: "travel_outdoor", label: "Travel and Outdoor" },

];

// Impact Options
export const STARTUP_CATEGORY = [
	{ value: "minority", label: "Minority Owned Business" },
	{ value: "minority_woman", label: "Minority & Woman Owned Business" },
	{ value: "other", label: "Other" },
];

export const NP_OPTIONS = [
	{ value: 0, label: "No" },
	{ value: 1, label: "Nonprofit" },
	{ value: 2, label: "Startup" },
];

export const APP_MARKETS = {
	market: [
		{ value: "columbus", label: "Columbus" },
		{ value: "tampa_bay", label: "Central Florida" },
		{ value: "cincinnati", label: "Cincinnati" },
		{ value: "springfield", label: "Springfield" },
		{ value: "pittsburgh", label: "Pittsburgh" },
		{ value: "indianapolis", label: "Indianapolis" },
		{ value: "cleveland", label: "Cleveland" },
		{ value: "charlotte", label: "Charlotte" },
		{ value: "atlanta", label: "Atlanta" },
	],
};

export const APP_LOCATIONS = {
	columbus: {
		locations: [
			{ value: "Upper Arlington", label: "Upper Arlington" },
			{ value: "Easton", label: "Easton" },
			{ value: "Polaris", label: "Polaris" },
			{ value: "The Gateway", label: "The Gateway" },
			{ value: "Delaware", label: "Delaware" },
			{ value: "Dublin", label: "Dublin" },
			{ value: "Westerville", label: "Westerville" },
			{ value: "Worthington", label: "Worthington" },
			{ value: "Bexley", label: "Bexley" },
		],
	},
	springfield: {
		locations: [{ value: "Springfield", label: "Springfield" }],
	},

	cincinnati: {
		locations: [
			{ value: "Covington", label: "Covington" },
			{ value: "Hyde Park", label: "Hyde Park" },
			{ value: "Mason", label: "Mason" },
			{ value: "Milford", label: "Milford" },
			{ value: "Kenwood", label: "Kenwood" },
			{ value: "Over The Rhine (OTR)", label: "Over The Rhine (OTR)" },
		],
	},
	cleveland: {
		locations: [
			{ value: "Beachwood", label: "Beachwood" },
			{ value: "Ohio City", label: "Ohio City" },
		],
	},

	indianapolis: {
		locations: [
			{ value: "Broad Ripple", label: "Broad Ripple" },
			{ value: "Carmel", label: "Carmel" },
			{ value: "Circle Centre", label: "Circle Centre" },
			{ value: "Noblesville", label: "Noblesville" },
			{ value: "Zionsville", label: "Zionsville" },
			{ value: "Polk Stables", label: "Polk Stables" },
		],
	},
	pittsburgh: {
		locations: [
			{ value: "Waterfront", label: "Waterfront" },
			{ value: "Southside Works", label: "Southside Works" },
			{ value: "Shadyside", label: "Shadyside" },
		],
	},
	tampa_bay: {
		locations: [
			{ value: "Lakeland", label: "Lakeland" },
			{ value: "St. Petersburg", label: "St. Petersburg" },
			{ value: "West Tampa", label: "West Tampa" },
			{ value: "Tarpon Springs", label: "Tarpon Springs" },
		],
	},
	charlotte: {
		locations: [
			{ value: "3RD Ward", label: "3RD Ward" },
			{ value: "Belmont", label: "Belmont" },
			// { value: "camp_greene", label: "Camp Greene" },
			{ value: "Camp North End", label: "Camp North End" },
			// { value: "Wesley Heights", label: "Wesley Heights" },
		],
	},
	atlanta: {
		locations: [{ value: "Duluth", label: "Duluth" },
			{ value: "lawrenceville", label: "Lawrenceville" },
		],
	},
};

export const COHATCH_MARKETS = {
	market: [
		{ value: "atlanta", label: "Atlanta" },
		{ value: "charlotte", label: "Charlotte" },
		{ value: "cincinnati", label: "Cincinnati" },
		{ value: "cleveland", label: "Cleveland" },
		{ value: "columbus", label: "Columbus" },
		{ value: "indianapolis", label: "Indianapolis" },
		{ value: "pittsburgh", label: "Pittsburgh" },
		{ value: "springfield", label: "Springfield" },
		{ value: "tampa_bay", label: "Central Florida" },
	],
};
export const COHATCH_LOCATIONS = {
	cities: [
		{
			columbus: {
				locations: [
					{ value: "bexley", label: "Bexley" },
					{ value: "delaware", label: "Delaware" },
					{ value: "dublin", label: "Dublin" },
					{ value: "easton", label: "Easton" },
					{ value: "gateway", label: "The Gateway" },
					{ value: "polaris", label: "Polaris" },
					{ value: "upper_arlington", label: "Upper Arlington" },
					{ value: "westerville", label: "Westerville" },
					{ value: "worthington_hardware", label: "Worthington - The Hardware Store" },
					{ value: "worthington_library", label: "Worthington - The Library & The Madery" },
				],
			},
			cincinnati: {
				locations: [
					{ value: "covington", label: "Covington" },
					{ value: "hyde_park", label: "Hyde Park" },
					{ value: "kenwood", label: "Kenwood" },
					{ value: "mason", label: "Mason" },
					{ value: "milford", label: "Milford" },
					{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
					
				],
			},

			cleveland: {
				locations: [
					{ value: "beachwood", label: "Beachwood" },
					{ value: "ohio_city", label: "Ohio City" },
				],
			},

			indianapolis: {
				locations: [
					{ value: "broad_ripple", label: "Broad Ripple" },
					{ value: "carmel", label: "Carmel" },
					{ value: "circle_centre", label: "Circle Centre" },
					{ value: "noblesville", label: "Noblesville" },
					{ value: "polk_stables", label: "Polk Stables" },
					{ value: "zionsville", label: "Zionsville" },
				],
			},

			pittsburgh: {
				locations: [
					{ value: "waterfront", label: "Waterfront" },
					{ value: "shadyside", label: "Shadyside" },
					{ value: "southside_works", label: "Southside works" },
				],
			},

			tampa_bay: {
				locations: [
					{ value: "lakeland", label: "Lakeland" },
					{ value: "st_petersburg", label: "St. Petersburg" },
					{ value: "tarpon springs", label: "Tarpon Springs" },
					{ value: "west_tampa", label: "West Tampa" },
				],
			},

			charlotte: {
				locations: [
					{ value: "3rd_ward", label: "3RD Ward" },
					{ value: "belmont", label: "Belmont" },
					{ value: "camp_greene", label: "Camp Green" },
					{ value: "camp_north_end", label: "Camp North End" },
					{ value: "wesley_heights", label: "Wesley Heights" },
				],
			},
			atlanta: {
				locations: [{ value: "duluth", label: "Duluth" },
					{ value: "lawrenceville", label: "Lawrenceville" },
				],
			},

			springfield: {
				locations: [
					{ value: "springfield", label: "Springfield" },
				],
			},
		},
	],
};

export const COHATCH_MEETING_ROOMS = {
	columbus: {
		bexley: [
			{ value: "arbor_room", label: "Arbor Room" },
			{ value: "capital_room", label: "Capital Room" },
			{ value: "first_ring_room", label: "First Ring Room" },
			{ value: "glow_room", label: "Glow Room" },
			{ value: "heritage_room", label: "Heritage Room" },
			{ value: "unicorn_room", label: "Unicorn Room" },
			{ value: "perch", label: "The Perch" },
			{ value: "reception_area", label: "Reception Area" },
		],
		delaware: [
			{ value: "black_and_white_room", label: "Black and White Room" },
			{ value: "comic_room", label: "Comic Room" },
			{ value: "hidden_room", label: "Hidden Room" },
			{ value: "press_room", label: "Press Room" },
			{ value: "stack_room", label: "Stack Room" },
			{ value: "top_spin_room", label: "The Top Spin Room" },
			{ value: "delaware_theater", label: "Delaware Theater" },
			{ value: "hall_of_mirrors", label: "Hall Of Mirrors (Patio Included-Book Black & White)" },
		],
		dublin: [
			{ value: "bridge_room", label: "Bridge Room" },
			{ value: "dublin_board_room", label: "Dublin Board Room" },
			{ value: "foliage_room", label: "Foliage Room" },
			{ value: "riverstone_room", label: "Riverstone Room" },
			{ value: "round_room", label: "Round Room" },
			{ value: "arena_at_mesh_fitness", label: "The Arena at Mesh Fitness" },
			{ value: "balance_room_at_mesh_fitness", label: "The Balance Room at Mesh Fitness" },
			{ value: "black_and_tan_room", label: "Black and Tan Room" },
			{ value: "commons", label: "Commons" },
		],
		easton: [
			{ value: "easton_podcast", label: "Easton Podcast" },
			{ value: "room_50", label: "Room 50" },
			{ value: "room_60", label: "Room 60" },
			{ value: "room_70", label: "Room 70" },
			{ value: "room_80", label: "Room 80" },
			{ value: "room_90", label: "Room 90" },
			{ value: "gathering_place", label: "The Gathering Place" },
			{ value: "rock_wall", label: "The Rock Wall" },
		],
		gateway: [
			{ value: "audiofile_room", label: "Audiofile Room" },
			{ value: "back_lab", label: "Back Lab" },
			{ value: "firewall_room", label: "Firewall Room" },
			{ value: "horseshoe_room", label: "Horseshoe Room" },
			{ value: "mirror_room", label: "Mirror Room" },
			{ value: "ram_room", label: "Ram Room" },
			{ value: "design_lab", label: "The Design Lab" },
		],
		polaris: [
			{ value: "ashland_suite", label: "Ashland Suite" },
			{ value: "confluence_room", label: "Confluence Room" },
			{ value: "fingerprint_room", label: "Fingerprint Room" },
			{ value: "honeycomb_room", label: "Honeycomb Room" },
			{ value: "middle_west", label: "Middle West" },
			{ value: "north_high", label: "North High" },
			{ value: "orbit_room", label: "Orbit Room" },
			{ value: "sand_room", label: "Sand Room" },
			{ value: "seeing_plaid", label: "Seeing Plaid" },
			{ value: "hub_table_16", label: "The Hub (Table for 16)" },
			{ value: "hub_lounge", label: "The Hub (The Lounge)" },
			{ value: "pavilion", label: "The Pavilion" },
			{ value: "underground_room", label: "Underground Room" },
			{ value: "mobi", label: "Mobi" },
			{ value: "polaris_east", label: "Polaris East" },
			{ value: "pub_pavilion", label: "The Pub - Book Pavillion" },
			{ value: "meridian", label: "The Meridian" },
			{ value: "west_wing", label: "West Wing" },
		],
		upper_arlington: [
			{ value: "45_room", label: "45 Room" },
			{ value: "armadillo_room", label: "Armadillo Room" },
			{ value: "black_and_gold_room", label: "Black & Gold Room" },
			{ value: "kodiak_room", label: "Kodiak Room" },
			{ value: "moss_room", label: "Moss Room" },
			{ value: "lookout", label: "The Lookout" },
		],
		westerville: [
			{ value: "dry_room", label: "Dry Room" },
			{ value: "hoover_room", label: "Hoover Room" },
			{ value: "ombre_room", label: "Ombre Room" },
			{ value: "tablemen_room", label: "Tablemen Room" },
			{ value: "cellar", label: "The Cellar" },
			{ value: "x_room", label: "X Room" },
			{ value: "garden_room", label: "Garden Room" },
			{ value: "armory_alley", label: "Armory Alley" },
		],
		worthington_hardware: [
			{ value: "alpha_room", label: "Alpha Room" },
			{ value: "ampersand_room", label: "Ampersand Room" },
			{ value: "launchpad", label: "Launchpad" },
			{ value: "bounce_house", label: "Bounce House" },
			{ value: "community_hall_kitchen", label: "Community Hall & Kitchen" },
		],
		worthington_library: [
			{ value: "back_room", label: "The Back Room" },
			{ value: "little_room", label: "The Little Room" },
			{ value: "open_room", label: "The Open Room" },
			{ value: "tall_room", label: "The Tall Room" },
			{ value: "kilbourne_room", label: "Kilbourne Room (+Patio+Kitchen+Game Room)" },
		],
	},
	cincinnati: {
		covington: [
			{ value: "barrell_room", label: "Barrell Room" },
			{ value: "blue_room", label: "Blue Room" },
			{ value: "green_line", label: "Green Line" },
			{ value: "map_room", label: "Map Room" },
			{ value: "chimes_room", label: "Chimes Room" },
			{ value: "the_summit", label: "The Summit - 30 Seats Event Space" },
		],
		hyde_park: [
			{ value: "board_room", label: "Board Room" },
			{ value: "pine_room", label: "Pine Room" },
			{ value: "poker_room", label: "Poker Room" },
			{ value: "radio_room", label: "Radio Room" },
			{ value: "launch_pad", label: "Launch Pad" },
			{ value: "brick", label: "The Brick" },
		],
		kenwood: [
			{ value: "chili_bowl", label: "The Chili Bowl" },
			{ value: "dipped_room", label: "The Dipped Room" },
			{ value: "paper_mill", label: "The Paper Mill" },
			{ value: "banana_phone", label: "Banana Phone" },
			{ value: "kitchenette_space", label: "Kitchenette Space" },
			{ value: "outdoor_patio", label: "Outdoor Patio" },
			{ value: "plaza_98", label: "Plaza 98" },
			{ value: "sports_sim", label: "Sports Sim" },
			{ value: "stardust", label: "The Stardust" },
		],
		mason: [
			{ value: "far_room", label: "Far Room" },
			{ value: "jailbird_room", label: "Jailbird Room" },
			{ value: "podcast_mason", label: "Podcast Mason" },
			{ value: "reveal_room", label: "Reveal Room" },
			{ value: "atrium", label: "Atrium" },
			{ value: "foyer_patio", label: "Foyer + Patio" },
			{ value: "mason_theatre", label: "Mason Theatre" },
			{ value: "reading_room", label: "Reading Room" },
			{ value: "behind_the_bookshelf", label: "Behind The Bookshelf" },
		],
		milford: [
			{ value: "moon_room", label: "Moon Room" },
			{ value: "radial_room", label: "Radial Room" },
			{ value: "upscale_room", label: "Upscale Room" },
			{ value: "blue_bike", label: "Blue Bike" },
			{ value: "deck", label: "Deck" },
			{ value: "gracie", label: "Gracie" },
			{ value: "red_bike", label: "Red Bike" },
			{ value: "silver_bike", label: "Silver Bike" },
			{ value: "barracuda", label: "The Barracuda" },
			{ value: "white_bike", label: "White Bike" },
		],
		over_the_rhine: [
			{ value: "cornhole_room", label: "Cornhole Room" },
			{ value: "mechanical_room", label: "Mechanical Room" },
			{ value: "opportunity_room", label: "Opportunity Room" },
			{ value: "treehouse", label: "The Treehouse" },
			{ value: "avalon", label: "Avalon" },
			{ value: "carpe_diem", label: "Carpe Diem" },
			{ value: "northern_liberties", label: "Northern Liberties" },
			{ value: "seven_hills_room", label: "Seven Hills Room" },
			{ value: "rooftop", label: "The Rooftop" },
		],
	},
	cleveland: {
		beachwood: [
			{ value: "room_with_a_view", label: "A Room With A View" },
			{ value: "clerical_room", label: "Clerical Room" },
			{ value: "crane_room", label: "Crane Room" },
			{ value: "expedition_room", label: "Expedition Room" },
			{ value: "open_room", label: "Open Room" },
			{ value: "portrait_room", label: "Portrait Room" },
			{ value: "rocket_room", label: "Rocket Room" },
			{ value: "north_high_brewing", label: "North High Brewing" },
			{ value: "open_space_cost", label: "Open Space Cost" },
			{ value: "hive", label: "The Hive" },
			{ value: "total_floor_buyout", label: "Total Floor Buyout" },
			{ value: "watkins_green", label: "The Watkin's Green" },
		],
		ohio_city: [
			{ value: "heisman_room", label: "Heisman Room" },
			{ value: "hinge_room", label: "Hinge Room" },
			{ value: "ink_room", label: "Ink Room" },
			{ value: "podcast_ohio_city", label: "Podcast Ohio City" },
			{ value: "ringside_room", label: "Ringside Room" },
			{ value: "front_lounge", label: "Front Lounge" },
			{ value: "moon_room", label: "Moon Room" },
		],
	},
	indianapolis: {
		broad_ripple: [
			{ value: "big_room", label: "Big Room" },
			{ value: "blueprint_room", label: "Blueprint Room" },
			{ value: "bison_room", label: "Bison Room" },
			{ value: "downstairs_kitchen_space", label: "Downstairs Kitchen Space" },
			{ value: "mulligan_room", label: "Mulligan Room" },
			{ value: "oxbow_room", label: "Oxbow Room" },
			{ value: "patio_main_kitchen_area", label: "Patio and Main Kitchen Area" },
			{ value: "pin_up_room", label: "Pin Up Room" },
			{ value: "podcast_broad_ripple", label: "Podcast Broad Ripple" },
			{ value: "stained_glass_room", label: "Stained Glass Room" },
			{ value: "two_24", label: "2-24" },
		],
		carmel: [
			{ value: "boombox_room", label: "Boombox Room"},
			{ value: "garden_room", label: "Garden Room" },
			{ value: "gearbox_room", label: "Gearbox Room" },
			{ value: "koen_room", label: "Koen Room" },
			{ value: "kitchen_reception_area", label: "Kitchen/Reception Area" },
			{ value: "lantern_room", label: "Lantern Room" },
			{ value: "noir_room", label: "Noir Room" },
			{ value: "station_38", label: "Station 38" },
		],
		noblesville: [
			{ value: "inside_outside", label: "Inside Outside" },
			{ value: "podcast_room", label: "Podcast Room" },
			{ value: "stripe_room", label: "Stripe Room" },
			{ value: "truss_room", label: "Truss Room" },
		],
		circle_centre: [
			{ value: "crown_jewel", label: "The Crown Jewel" },
			{ value: "growth_room", label: "Growth Room" },
			{ value: "speedway_room", label: "Speedway Room" },
			{ value: "storefront_room", label: "Storefront Room" },
			{ value: "tavern_garage", label: "The Tavern & The Garage" },
		],
		zionsville: [
			{ value: "gravity_room", label: "Gravity Room" },
			{ value: "hipster_cast", label: "Hipster Cast" },
			{ value: "kings_room", label: "King's Room" },
			{ value: "main_coworking_space_kitchen", label: "Main Coworking Space and Kitchen" },
			{ value: "mixtape_room", label: "Mixtape Room" },
			{ value: "word_room", label: "Word Room" },
		],
		polk_stables: [
			{ value: "1872_room", label: "1872 Room" },
			{ value: "bee_line_room", label: "Bee Line Room" },
			{ value: "cannery_balcony", label: "The Cannery & Balcony" },
			{ value: "chess_room", label: "Chess Room" },
			{ value: "dairy_room", label: "The Dairy Room" },
			{ value: "green_room_patio", label: "The Green Room & Patio" },
			{ value: "montgomery_room", label: "Montgomery Room" },
			{ value: "polklore", label: "Polklore" },
			{ value: "sound_cave", label: "Sound Cave" },
			{ value: "sunshine_studios", label: "Sunshine Studios" },
		],
	},
	pittsburgh: {
		waterfront: [
		  { value: "412_room", label: "412 Room" },
		  { value: "atrium_open_coworking_space", label: "Atrium/Open Coworking Space" },
		  { value: "furnace_room", label: "Furnace Room" },
		  { value: "green_room", label: "Green Room" },
		  { value: "incline_loft", label: "Incline Loft" },
		  { value: "mill_room", label: "Mill Room" },
		  { value: "smithfield_theater", label: "Smithfield Theater" },
		  { value: "westwood_room", label: "Westwood Room" },
		],
		shadyside: [
		  { value: "basement_event_space", label: "Basement Event Space" },
		  { value: "coworking_first_floor", label: "Coworking First Floor" },
		  { value: "fahlman_room", label: "Fahlman Room" },
		  { value: "roslyn_room", label: "Roslyn Room" },
		  { value: "rider_penthouse", label: "Rider Penthouse" },
		  { value: "spoonwood_room", label: "Spoonwood Room" },
		  { value: "spruce_room", label: "Spruce Room" },
		  { value: "ward_hall", label: "Ward Hall" },
		],
		southside_works: [
		  { value: "art_soul", label: "Art & Soul" },
		  { value: "beware_the_ouga", label: "Beware the Ogua" },
		  { value: "carson_room", label: "Carson Room" },
		  { value: "firefly_room", label: "Firefly Room" },
		  { value: "glass_room", label: "Glass Room" },
		  { value: "morning_glory", label: "Morning Glory" },
		  { value: "cm_desk_area", label: "CM Desk Area" },
		  { value: "pixel", label: "The Pixel" },
		],
	  },
	  tampa_bay: {
		lakeland: [
		  { value: "longwing_room", label: "Longwing Room" },
		  { value: "polk_room", label: "Polk Room" },
		  { value: "skipper_room", label: "Skipper Room" },
		  { value: "bridge", label: "The Bridge" },
		  { value: "nest", label: "The Nest" },
		  { value: "cafe", label: "The Cafe" },
		  { value: "everglade", label: "The Everglade" },
		],
		st_petersburg: [
		  { value: "768_room", label: "768 Room" },
		  { value: "ballroom", label: "Ballroom" },
		  { value: "manhattan_room", label: "Manhattan Room" },
		  { value: "flora_room", label: "Flora Room" },
		  { value: "lager_lounge", label: "Lager Lounge" },
		],
		tarpon_springs: [
			{ value: "beam_room", label: "Beam Room" },
			{ value: "aquarium", label: "The Aquarium" },
			{ value: "muse_room", label: "Muse Room" },
			{ value: "yoyo_room", label: "YoYo Room" },
			{ value: "breezeway", label: "The Breezeway" },
		  ],
		  west_tampa: [
			{ value: "lunchroom", label: " Lunchroom" },
			{ value: "big_guava", label: "Big Guava" },
			{ value: "the_streetcar", label: "The Streetcar" },
			{ value: "the_wilds", label: "The Wilds" },
			{ value: "container_stage", label: "Container Stage" },
			{ value: "the_humidor", label: "The Humidor" },
		  ],
	  },
	  charlotte: {
		"3rd_ward": [
		  { value: "treehouse", label: "Treehouse" },
		  { value: "huddle_room", label: "Huddle Room" },
		],
		belmont: [
		  { value: "mill_village", label: "Mill Village" },
		  { value: "wiggle_room", label: "Wiggle Room" },
		  { value: "eastend", label: "Eastend" },
		  { value: "parkwood", label: "Parkwood" },
		  { value: "cordelia", label: "Cordelia" },
		  { value: "sunnyside_spot", label: "Sunnyside Spot" },
		],
		camp_green: [
		  { value: "nice_to_meet_you", label: "Nice To Meet You" },
		  { value: "more_meetings_more_problems", label: "More Meetings, More Problems" },
		],
		camp_north_end: [
		  { value: "blue_classroom", label: "Blue Classroom" },
		  { value: "huddle_room", label: "Huddle Room" },
		],
		wesley_heights: [
		  { value: "fancy_meeting_you_here", label: "Fancy Meeting You Here" },
		  { value: "please_to_meet_you", label: "Please To Meet You" },
		  { value: "podcast_studio", label: "Podcast Studio" },
		  { value: "meeting_of_the_minds", label: "Meeting Of The Minds" },
		  { value: "pencil_me_in", label: "Pencil Me In" },
		  { value: "lets_circle_up", label: "Let's Circle Up" },
		],
	  },
	  atlanta: {
		duluth: [
		  { value: "divide_room", label: "Divide Room" },
		  { value: "festival_room", label: "Festival Room" },
		  { value: "suds_room", label: "Suds Room" },
		  { value: "workshop_room", label: "Workshop Room" },
		  { value: "outdoor_patio", label: "Outdoor Patio" },
		  { value: "annex_private_patio", label: "The Annex + Private Patio" },
		],
	  },

	  springfield: {
		springfield: [
			{ value: "corner_room", label: "Corner Room" },
			{ value: "heritage_room", label: "Heritage Room" },
			{ value: "rise_room", label: "Rise Room" },
			{ value: "the_basement", label: "The Basement" },
			{ value: "the_exchange", label: "The exchange" },
			{ value: "the_mezzanine", label: "The Mezzanine" },
		],
	  },
};