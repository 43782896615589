import React from "react";
import Footer from "../../../components/footer/Footer";
import HomeNavigation from "../../../components/home_navigation/HomeNavigation";
import useScrollPage from "../../../hooks/useScrollPage";
import useTitle from "../../../hooks/useTitle";

function TermsOfSale() {
  useScrollPage();
  useTitle("COhatch+ Terms Of Sale Policy");

  return (
    <div className="w-full flex flex-col">
      <div className="sticky top-0 w-full z-[999999]">
        <HomeNavigation />
      </div>
      <div className="w-full py-8 px-5 text-center space-y-3 bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover">
        <h1 className="w-full text-4xl md:text-5xl font-bebas text-success-800 font-bold">COhatch+ Terms of Sale
        </h1>
      </div>


      <section>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-12">
            <div className="text-lg  mx-auto">
              <h1>
                <span className="block text-base text-2xl text-center text-gray-500 font-semibold tracking-wide">
                  This page provides the terms of sale and purchase for COhatch+ subscriptions offered by COhatch Marketplace, LLC dba COhatch+ (“COhatch”). By subscribing to COhatch+, you agree to these Terms of Sale, our General Terms of Use, and our Policy .
                </span>

                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  1. General Terms of Sale
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                We reserve the right to modify the content, type and availability of any features of COhatch+ at any time.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                Only one person may use the username and password associated with a COhatch+ account.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                For details about your purchases, including payment methods and billing cycles, visit the Account area of COhatchplus.com.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                A. Processing and Payment. COhatch will process your purchase as promptly as possible. There may be a delay in the activation of your product while payment details are verified. We accept major credit cards. We reserve the right to reject any order or purchase at any time. If your initial payment authorization is later revoked, your subscription and access to COhatch+ will be terminated. Please contact support@cohatchplus.com if you believe your access was terminated in error. Unless specified otherwise in the Cancellation and Refund Policy, all charges are nonrefundable.
                <p className="text-base text-gray-500 leading-8">
                  To view or change your payment method, visit the Account Settings area of COhatchplus.com. We may receive updated credit card information (new credit card number or updated expiration date) from your credit card issuer. The disbursement of the updated credit card information is provided to us at the election of your credit card issuer. We are not responsible for the distribution of your updated credit card information. Your credit card issuer may give you the right to opt out of the update service. It is your responsibility to contact your credit card issuer with regards to your right to opt out of the update service.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                B. Pricing. When you purchase a COhatch+ subscription, the price will be made clear during the order process. You agree to pay the price that is stated at the time of your order, as well as any applicable taxes. You also agree to the billing frequency stated at the time of your order.
                <p className="text-base text-gray-500 leading-8">
                  COhatch will provide a Member, as that term is defined in the COhatch Membership Agreement, with access to COhatch+ features for the duration of the Membership Term, at no extra cost. After the Membership Term ends, COhatch will continue to provide Member with access to COhatch+ at the current monthly rate for COhatch+ subscribers, until Member cancels the COhatch+ subscription.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  Discount eligibility is determined at the time of the order. Discounts cannot be applied retroactively.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  We reserve the right to change prices and fees at any time. We will notify you in advance if the regular rate of a product changes from what was stated at the time of your order. You will have the opportunity to accept the new price or cancel your subscription or purchase from that point forward.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  If a stated price or other material information is determined by us in our sole discretion to be in error, we are not under any obligation to offer you the product at that price and reserve the right to cancel, to terminate or not to process orders (including accepted orders). We will notify you of the error and give you the opportunity to cancel your order and obtain a refund if payment has already been made.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  If you believe someone else has used your account or you are being charged for a product you do not have, please support@cohatchplus.com.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                C. Billing. We will charge or debit your payment method at the beginning of your virtual subscription to COhatch+ or, if applicable, at the end your Membership Term, as that term is defined in your COhatch Membership Agreement. Billing will continue according to the cycle stated at the time of your order.
                <p className="text-base text-gray-500 leading-8">
                  All COhatch+ subscriptions are renewed automatically. When we renew your subscription, we will use the payment method currently associated with your account. If you redeem a gift subscription and choose to extend your subscription at the time of redemption, that subscription will renew automatically. You can view your payment method at any time in the Account area of NYTimes.com.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  In most cases, you will not be notified in advance of impending renewals and you expressly agree to waive the application of any laws requiring notice of impending renewals. Please see our Cancellation and Refund policy below for information about canceling.
                </p>
                <p className="text-base text-gray-500 leading-8">
                  To view information about your payment methods and billing cycle, visit the Account Settings area of COhatchplus.com.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                D. Unpaid Charges. If your credit card expires or your payment method is otherwise invalid, your COhatch+ subscription will not automatically be terminated. You will remain responsible for all charges. You will be responsible for all costs we incur in connection with the collection of unpaid amounts, including court costs, attorneys' fees, collection agency fees and any other associated costs.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                E. Promotions. COhatch may occasionally offer promotions. The specific terms of each promotion are stated at the time the promotion is offered. Each promotion may be different. Promotions cannot be combined. You are required to provide your payment details when you sign up for a promotion. At the end of the promotion, your subscription will automatically renew at the rates displayed at the time of purchase. We will not notify you in advance that the promotion is about to end and we will not notify you when your promotional rate has ended. To cancel and avoid being charged, you must notify us before the promotion ends.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                F. Electronic Notifications. You consent to receive notifications from us electronically to the email address you provide to us. You agree that all notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing, unless mandatory applicable laws specifically require a different form of communication. You agree that you have the ability to store such electronic communications such that they remain accessible to you in an unchanged form.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                G. Severability. If any section of these Terms of Sale is deemed invalid, void, or for any reason unenforceable, that section will be deemed severable and will not affect the validity and enforceability of any remaining sections.
              </p>
            </div>

            <div className="text-lg  mx-auto">
              <h1>
                <span className="mt-8 block text-3xl text-left font-bebas leading-8 font-extrabold text-primary-400 sm:text-4xl">
                  2. Cancellation and Refund Policy
                </span>
              </h1>
              <p className="mt-4 text-base text-gray-500 leading-8">
                A. Cancellation and Refunds of COhatch+ Subscriptions. When you cancel a subscription, you cancel only future charges associated with your subscription. Except in the case of certain promotions, you may cancel your digital subscription in your account settings or by contacting us at any time at support@cohatch.com. You may notify COhatch of your intent to cancel at any time, but the cancellation will become effective at the end of your current billing period. You will not receive a refund for the current billing cycle. You will continue to have the same access to COhatch+ for the remainder of the current billing period.
                <p className="text-base text-gray-500 leading-8">
                  We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.
                </p>
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                B. Changes to Content or Access. We reserve the right to make changes to our features at any time. If we temporarily reduce or eliminate the charge for content or access that you are currently paying for under different terms, you will not receive a refund.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                C. Cancellations by Us. We reserve the right to suspend or terminate your subscription or product for any reason, with or without notice and without further obligation. You will not be entitled to a refund in these circumstances. If any or all of our digital products are temporarily unavailable, you will not receive a refund. We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.
              </p>
              <p className="mt-4 text-base text-gray-500 leading-8">
                D. Cancellation During Promotional Periods. Certain promotions may not permit cancellation during the promotional period. You agree to the cancellation and refund terms stated at the time of purchase.
              </p>
            </div>


            <div className="text-lg  mx-auto">
              <p className="mt-12 text-base text-gray-500 leading-8">
                Last updated: March 10, 2022
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default TermsOfSale;