/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PLANS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import UpgradeModel from "../upgrade_modal/UpgradeModel";
import NotFound from "../../pages/not_found/NotFound";

function Feature({ excludedPlans }) {
  const {
    subscription: { product },
  } = useUserContext();

  // If the user is DOCSIGNEE, show 404
  if (PLANS[product] === "DOCSIGNEE") {
    return <NotFound />;
  }

  // If the user is in excludedPlans, show UpgradeModel
  return excludedPlans.includes(PLANS[product]) ? <UpgradeModel /> : <Outlet />;
}

export default Feature;
